import { Document, Page } from '@react-pdf/renderer'
import styles from './PDF.styled'
import Sostitutiva from './Sostitutiva'
import DelegaErede from './DelegaErede'
import { parseEredi } from './DataParser'
import { DeclarationData, DelegaEredeData } from './PDF.types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PDF = ({ data }: { data: any }) => {
  const [delega, dichiarazione] = parseEredi(data) as [
    DelegaEredeData,
    DeclarationData,
  ]
  // console.log(delega, dichiarazione)
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <DelegaErede data={delega} />
      </Page>
      <Page size="A4" style={styles.page} wrap>
        <Sostitutiva data={dichiarazione} />
      </Page>
    </Document>
  )
}

export default PDF
