import {
  StyledBreadcrumbsWrapper,
  StyledBreadcrumb,
  StyledBreadcrumbLink,
  StyledBreadcrumbDivider,
  StyledOverflowGradient,
} from './Breadcrumbs.style'
import { SingleBreadcrumbProps, BreadcrumbsProps } from './breadcrumb.types'
import { FC } from 'react'

const Breadcrumb: FC<SingleBreadcrumbProps> = ({ link, isLast }) => {
  return (
    <StyledBreadcrumb>
      <StyledBreadcrumbLink
        to={link.uri}
        target={link.target || '_self'}
        $disabled={!link.isActive && isLast}
      >
        {link.title}
      </StyledBreadcrumbLink>
      {!isLast && <StyledBreadcrumbDivider>/</StyledBreadcrumbDivider>}
    </StyledBreadcrumb>
  )
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({
  links,
  className,
  showMobile = true,
}) => {
  return (
    <div style={{ position: 'relative' }}>
      <StyledBreadcrumbsWrapper className={className} $showMobile={showMobile}>
        {links.map((link, i) => (
          <Breadcrumb key={i} link={link} isLast={links.length === i + 1} />
        ))}
        <StyledOverflowGradient />
      </StyledBreadcrumbsWrapper>
    </div>
  )
}

export default Breadcrumbs
