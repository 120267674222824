import { Erede, ErediContextState } from '../../context/ErediContext'

export const parseEredi = (data: ErediContextState) => {
  return !data
    ? [false, false]
    : [
        {
          eredi: data.eredi
            ? data.eredi.map((x: Erede) => ({
                name: x.nome,
                surname: x.cognome,
                birthplace: x.comuneNascita,
                birthcountry: x.provinciaNascita,
                birthdate: x.dataNascita,
                fiscalcode: x.codiceFiscale,
                city: x.comuneResidenza,
                country: x.provinciaResidenza,
                address: x.indirizzoResidenza,
                number: x.numeroCivico,
              }))
            : [],
          delegated: data.delegatoIsSottoscritto
            ? {
                name: data.eredi[0]?.nome,
                surname: data.eredi[0]?.cognome,
                birthplace: data.eredi[0]?.comuneNascita,
                birthdate: data.eredi[0]?.dataNascita,
                city: data.eredi[0]?.comuneResidenza,
                address: data.eredi[0]?.indirizzoResidenza,
                number: data.eredi[0]?.numeroCivico,
                birthcountry: data.eredi[0]?.provinciaNascita,
                fiscalcode: data.eredi[0]?.codiceFiscale,
                country: data.eredi[0]?.provinciaResidenza,
              }
            : {
                name: data.delegato.nome,
                surname: data.delegato.cognome,
                birthplace: data.delegato.comuneNascita,
                birthdate: data.delegato.dataNascita,
                city: data.delegato.comuneResidenza,
                address: data.delegato.indirizzoResidenza,
                number: data.delegato.numeroCivico,
                birthcountry: data.delegato.provinciaNascita,
                fiscalcode: data.delegato.codiceFiscale,
                country: data.delegato.provinciaResidenza,
              },
          death: {
            name: data.deceduto?.nome,
            surname: data.deceduto?.cognome,
            fiscalcode: data.deceduto?.codiceFiscale,
            birthplace: data.deceduto?.comuneNascita,
            birthdate: data.deceduto?.dataNascita,
            deathdate: data.deceduto?.dataDecesso,
          },
          delega: {
            number: data.fornitura ? data.fornitura : '',
          },
        },
        {
          user: data.eredi.length
            ? {
                name: data.eredi[0]?.nome,
                surname: data.eredi[0]?.cognome,
                birthplace: data.eredi[0]?.comuneNascita,
                birthdate: data.eredi[0]?.dataNascita,
                city: data.eredi[0]?.comuneResidenza,
                address: data.eredi[0]?.indirizzoResidenza,
                number: data.eredi[0]?.numeroCivico,
              }
            : {},
          death: data.deceduto
            ? {
                name: data.deceduto.nome,
                surname: data.deceduto.cognome,
                birthplace: data.deceduto.comuneNascita,
                birthdate: data.deceduto.dataNascita,
                city: data.deceduto.comuneDecesso,
                address: data.deceduto.indirizzoDecesso,
                number: data.deceduto.numeroCivico,
                contract: data.fornitura,
                deathdate: data.deceduto.dataDecesso,
                will: data.testamento === 'testamento-si',
                legitimate: data.tipoEredi?.includes('legittimi'),
                testamentary: data.tipoEredi?.includes('testamentari'),
              }
            : {},
          heirs: data.eredi
            ? data.eredi.map((x: Erede) => ({
                name: x.nome,
                surname: x.cognome,
                birthplace: x.comuneNascita,
                birthdate: x.dataNascita,
                city: x.comuneResidenza,
                address: x.indirizzoResidenza,
                nationality: x.nazionalita,
                number: x.numeroCivico,
                kinship: x.legameParentela?.label,
              }))
            : [],
          renouncers:
            data.rinunciatari && typeof data.rinunciatari[0] !== 'undefined'
              ? data.rinunciatari
                  .filter((x) => x?.label)
                  .map((x) => ({
                    name: x?.label,
                  }))
              : [],
          bank: {
            checked: data.pagamento?.type === 'bonifico',
            iban:
              data.pagamento?.type === 'bonifico' &&
              data.pagamento?.value?.iban,
            beneficiario: data.delegatoIsSottoscritto
              ? `${data.eredi[0]?.nome} ${data.eredi[0]?.cognome}`
              : `${data.delegato.nome} ${data.delegato.cognome}`,
            name:
              data.pagamento?.type === 'bonifico' &&
              data.pagamento?.value?.banca,
            agency:
              data.pagamento?.type === 'bonifico' &&
              data.pagamento?.value?.filiale,
            swift:
              data.pagamento?.type === 'bonifico' &&
              data.pagamento?.value?.swift,
          },
          delegatedCheck: {
            checked: data.pagamento?.type === 'assegno',
            beneficiario: data.delegatoIsSottoscritto
              ? `${data.eredi[0]?.nome} ${data.eredi[0]?.cognome}`
              : `${data.delegato.nome} ${data.delegato.cognome}`,
            address:
              data.pagamento?.type === 'assegno' && data.delegatoIsSottoscritto
                ? data.eredi[0]?.indirizzoResidenza
                : data.delegato.indirizzoResidenza,
            number:
              data.pagamento?.type === 'assegno' && data.delegatoIsSottoscritto
                ? data.eredi[0]?.numeroCivico
                : data.delegato.numeroCivico,
            city:
              data.pagamento?.type === 'assegno' && data.delegatoIsSottoscritto
                ? data.eredi[0]?.comuneResidenza
                : data.delegato.comuneResidenza,
            country:
              data.pagamento?.type === 'assegno' && data.delegatoIsSottoscritto
                ? data.eredi[0]?.provinciaResidenza
                : data.delegato.provinciaResidenza,
            cap:
              data.pagamento?.type === 'assegno' && data.delegatoIsSottoscritto
                ? data.eredi[0]?.CAP
                : data.delegato.CAP,
          },
        },
      ]
}
