import { useContext } from 'react'
import { Input } from '../../../commons/FormComponents/Input'
import { StyledH4 } from '../../../commons/Theme'
import { FlexColumn, StyledFornitura } from '../FormDelegaRiscossione.style'
import { ErediContext } from '../context/ErediContext'
import { errorHandler } from '../helpers/utils'

export const FormFornitura = () => {
  const { state, dispatch, errors, dispatchError, triggerValidation } =
    useContext(ErediContext)

  return (
    <FlexColumn $gap="40px">
      <StyledH4 $weight="500">Forniture:</StyledH4>
      <FlexColumn $gap="24px">
        <StyledFornitura>
          <Input
            id="fornitura"
            label="Indicare almeno un numero fornitura"
            value={state.fornitura}
            onChange={(value) =>
              dispatch({
                type: 'UPDATE_FORNITURA',
                payload: value,
              })
            }
            onFocus={() => {
              dispatchError({
                type: 'UPDATE_ERROR_VALUE',
                payload: {
                  [`fornitura`]: '',
                },
              })
            }}
            error={errors[`fornitura`] || ''}
            isValid={errors[`fornitura`] === ''}
            validate={() =>
              dispatchError({
                type: 'UPDATE_ERROR_VALUE',
                payload: {
                  [`fornitura`]: errorHandler(
                    'fornitura',
                    state.fornitura || ''
                  ),
                },
              })
            }
            tooltip={
              <>
                Per individuare il tuo numero di fornitura, consulta la parte
                superiore destra della bolletta.
              </>
            }
            validateOnBlur
            triggerValidation={triggerValidation}
          />
        </StyledFornitura>
      </FlexColumn>
    </FlexColumn>
  )
}

export const FormFornituraReadOnly = () => {
  const { state } = useContext(ErediContext)

  return (
    <FlexColumn $gap="24px">
      <StyledFornitura>
        <Input
          label="Numero fornitura"
          value={state.fornitura}
          onChange={() => {}}
          readOnly
        />
      </StyledFornitura>
    </FlexColumn>
  )
}
