import { createContext, useReducer, useState } from 'react'
import { Option } from '../../../commons/FormComponents/SelectField'

const defaulOption: Option = {
  label: '',
  value: '',
}

export type Erede = {
  nome: string
  cognome: string
  comuneNascita: string
  provinciaNascita: string
  dataNascita: string
  nazionalita: string
  codiceFiscale: string
  legameParentela: Option
  comuneResidenza: string
  provinciaResidenza: string
  indirizzoResidenza: string
  CAP?: string
  numeroCivico: string
  telefono?: string
  codiceResidenza?: string
}

type TipoEredi = 'testamentari' | 'legittimi'
export type TestamentoType = 'testamento-si' | 'testamento-no'

type Deceduto = {
  nome: string
  cognome: string
  comuneNascita: string
  provinciaNascita: string
  dataNascita: string
  nazionalita: string
  codiceFiscale: string
  dataDecesso: string
  comuneDecesso: string
  provinciaDecesso: string
  indirizzoDecesso: string
  numeroCivico: string
  codiceDecesso?: string
}

export type ErrorFields =
  | keyof Erede
  | keyof Deceduto
  | keyof ErediContextState
  | 'obbligatorio'
  | 'ibanItaliano'

type PagamentoType =
  | {
      type: 'assegno'
      value: object
    }
  | {
      type: 'bonifico'
      value: {
        iban: string
        swift: string
        banca: string
        filiale: string
        isIBANStraniero: boolean
      }
    }
  | null

export interface ErediContextState {
  eredi: Erede[]
  deceduto: Deceduto
  delegato: Erede
  fornitura: string
  email: string
  testamento: string
  tipoEredi: TipoEredi[]
  rinunciatari: Array<Option | undefined>
  pagamento: PagamentoType
  delegatoIsSottoscritto: boolean
  allegato?: string
}

type ErediReducerAction =
  | {
      type: 'ADD_EREDE' | 'ADD_RINUNCIATARIO'
    }
  | {
      type: 'UPDATE_EREDE_VALUE'
      payload: {
        index: number
        value: object
      }
    }
  | {
      type: 'UPDATE_RINUNCIATARIO'
      payload: {
        index: number
        value: Option
      }
    }
  | {
      type:
        | 'UPDATE_DECEDUTO_VALUE'
        | 'UPDATE_PAGAMENTO_VALUE'
        | 'UPDATE_DELEGATO_VALUE'
      payload: object
    }
  | {
      type: 'UPDATE_DELEGATO_TYPE'
      payload: boolean
    }
  | {
      type: 'UPDATE_TESTAMENTO_VALUE'
      payload: TestamentoType
    }
  | {
      type: 'UPDATE_EMAIL_VALUE' | 'UPDATE_FORNITURA'
      payload: string
    }
  | {
      type: 'UPDATE_PAGAMENTO_TYPE'
      payload: 'assegno' | 'bonifico'
    }
  | {
      type: 'ADD_TIPO_EREDE' | 'DELETE_TIPO_EREDE'
      payload: TipoEredi
    }
  | {
      type: 'DELETE_EREDE' | 'DELETE_RINUNCIATARIO'
      payload: { index: number }
    }

interface ErediContextType {
  state: ErediContextState
  dispatch: React.Dispatch<ErediReducerAction>
  errors: ErrorContextState
  dispatchError: React.Dispatch<ErrorReducerAction>
  triggerValidation: boolean
  setTriggerValidation: React.Dispatch<React.SetStateAction<boolean>>
}

const initialState: ErediContextState = {
  eredi: [
    {
      nome: '',
      cognome: '',
      comuneNascita: '',
      provinciaNascita: '',
      dataNascita: '',
      nazionalita: '',
      codiceFiscale: '',
      legameParentela: { label: '', value: '' },
      comuneResidenza: '',
      provinciaResidenza: '',
      indirizzoResidenza: '',
      numeroCivico: '',
    },
  ],
  deceduto: {
    nome: '',
    cognome: '',
    comuneNascita: '',
    provinciaNascita: '',
    dataNascita: '',
    nazionalita: '',
    codiceFiscale: '',
    dataDecesso: '',
    comuneDecesso: '',
    provinciaDecesso: '',
    indirizzoDecesso: '',
    numeroCivico: '',
  },
  delegato: {
    nome: '',
    cognome: '',
    comuneNascita: '',
    provinciaNascita: '',
    dataNascita: '',
    nazionalita: '',
    codiceFiscale: '',
    legameParentela: { label: '', value: '' },
    comuneResidenza: '',
    provinciaResidenza: '',
    indirizzoResidenza: '',
    numeroCivico: '',
  },
  delegatoIsSottoscritto: true,
  fornitura: '',
  email: '',
  testamento: '',
  tipoEredi: [],
  rinunciatari: [{ ...defaulOption }],
  pagamento: {
    type: 'bonifico',
    value: {
      iban: '',
      banca: '',
      swift: '',
      filiale: '',
      isIBANStraniero: false,
    },
  },
}

export const ErediContext = createContext<ErediContextType>({
  state: initialState,
  dispatch: () => {},
  errors: {},
  dispatchError: () => {},
  triggerValidation: false,
  setTriggerValidation: () => {},
})

const erediReducer = (
  state: ErediContextState,
  action: ErediReducerAction
): ErediContextState => {
  switch (action.type) {
    case 'ADD_EREDE':
      return {
        ...state,
        eredi: [
          ...state.eredi,
          {
            nome: '',
            cognome: '',
            comuneNascita: '',
            provinciaNascita: '',
            dataNascita: '',
            nazionalita: '',
            codiceFiscale: '',
            legameParentela: { label: '', value: '' },
            comuneResidenza: '',
            provinciaResidenza: '',
            indirizzoResidenza: '',
            numeroCivico: '',
          },
        ],
      }
    case 'ADD_TIPO_EREDE':
      return {
        ...state,
        tipoEredi: [...state.tipoEredi, action.payload],
      }
    case 'ADD_RINUNCIATARIO':
      return {
        ...state,
        rinunciatari: [...state.rinunciatari, { ...defaulOption }],
      }
    case 'UPDATE_EREDE_VALUE':
      return {
        ...state,
        eredi: state.eredi.map((erede, i) => {
          if (i === action.payload.index) {
            return { ...erede, ...action.payload.value }
          } else {
            return erede
          }
        }),
      }
    case 'UPDATE_PAGAMENTO_VALUE':
      return {
        ...state,
        //@ts-expect-error Pagamento type must be improved
        pagamento: state.pagamento
          ? {
              ...state.pagamento,
              value: { ...state.pagamento.value, ...action.payload },
            }
          : null,
      }
    case 'UPDATE_DECEDUTO_VALUE':
      return {
        ...state,
        deceduto: {
          ...state.deceduto,
          ...action.payload,
        },
      }
    case 'UPDATE_DELEGATO_VALUE':
      return {
        ...state,
        delegato: {
          ...state.delegato,
          ...action.payload,
        },
      }
    case 'UPDATE_FORNITURA':
      return {
        ...state,
        fornitura: action.payload,
      }
    case 'UPDATE_EMAIL_VALUE':
      return {
        ...state,
        email: action.payload,
      }
    case 'UPDATE_TESTAMENTO_VALUE':
      return {
        ...state,
        testamento: action.payload,
      }
    case 'UPDATE_RINUNCIATARIO':
      return {
        ...state,
        rinunciatari: state.rinunciatari.map((value, index) =>
          index === action.payload.index ? action.payload.value : value
        ),
      }
    case 'UPDATE_PAGAMENTO_TYPE':
      return {
        ...state,
        pagamento:
          action.payload === 'assegno'
            ? {
                type: 'assegno',
                value: {},
              }
            : {
                type: 'bonifico',
                value: {
                  iban: '',
                  banca: '',
                  filiale: '',
                  isIBANStraniero: false,
                  swift: '',
                },
              },
      }
    case 'UPDATE_DELEGATO_TYPE':
      return {
        ...state,
        delegatoIsSottoscritto: action.payload,
      }
    case 'DELETE_EREDE':
      return {
        ...state,
        eredi: state.eredi.filter((_, index) => index !== action.payload.index),
      }
    case 'DELETE_TIPO_EREDE':
      return {
        ...state,
        tipoEredi: state.tipoEredi.filter((value) => value !== action.payload),
      }
    case 'DELETE_RINUNCIATARIO':
      return {
        ...state,
        rinunciatari: state.rinunciatari.filter(
          (_, index) => index !== action.payload.index
        ),
      }
    default:
      return state
  }
}

type ErrorContextState = {
  [key: string]: string
}

type ErrorReducerAction =
  | {
      type: 'UPDATE_ERROR_VALUE'
      payload: object
    }
  | {
      type: 'CLEAN_ERRORS_WITH_KEY'
      payload: string
    }

const errorReducer = (
  state: ErrorContextState,
  action: ErrorReducerAction
): ErrorContextState => {
  switch (action.type) {
    case 'UPDATE_ERROR_VALUE':
      return {
        ...state,
        ...action.payload,
      }
    case 'CLEAN_ERRORS_WITH_KEY':
      return Object.keys(state).reduce((acc, key) => {
        acc[key] = key.includes(action.payload) ? '' : state[key]
        return acc
      }, {} as ErrorContextState)
    default:
      return state
  }
}

export const ErediContextProvider = ({
  children,
}: {
  children: React.ReactElement
}) => {
  const [triggerValidation, setTriggerValidation] = useState<boolean>(false)
  const [state, dispatch] = useReducer(erediReducer, initialState)
  const [errors, dispatchError] = useReducer(errorReducer, {})

  return (
    <ErediContext.Provider
      value={{
        state,
        dispatch,
        errors,
        dispatchError,
        triggerValidation,
        setTriggerValidation,
      }}
    >
      {children}
    </ErediContext.Provider>
  )
}
