import { useEffect } from 'react'
import styled from 'styled-components'
import { AtomButton } from '../../../atoms/AtomButton'
import { BootstrapCols } from '../../../commons/BootstrapCols'
import { Colors, StyledH3, StyledP } from '../../../commons/Theme'
import { Icon } from '../../Icons/Icon'
import { SadIcon } from '../../Icons/SadIcon'
import { TYPSmileIcon } from '../../Icons/TYPSmileIcon'
import { FlexColumn } from '../FormDelegaRiscossione.style'

const StyledFormTyp = styled.div`
  background: ${Colors.blue100};
  border: 1px solid ${Colors.blue200};
  border-radius: 8px;
  padding: 24px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;

  text-align: center;
`

export const FormTYP = ({
  type,
  onBack,
  okTitle,
  okBody,
  koTitle,
  koBody,
}: {
  type: 'ok' | 'ko' | null
  onBack: () => void
  okTitle: string
  koTitle: string
  okBody: React.ReactNode
  koBody: React.ReactNode
}) => {
  useEffect(() => {
    // Reset scroll to top of the page
    window.scrollTo(0, 0)
  }, [])

  return (
    <BootstrapCols desktopCols={8}>
      <StyledFormTyp>
        {type === 'ok' ? (
          <>
            <Icon
              stroke={Colors.success}
              width="64px"
              tabletWidth="80px"
              desktopWidth="120px"
              Icon={TYPSmileIcon}
            />
            <FlexColumn $gap="16px">
              <StyledH3 $weight="500">{okTitle}</StyledH3>
              <StyledP>{okBody}</StyledP>
            </FlexColumn>
          </>
        ) : (
          <>
            <Icon
              stroke={Colors.azure600}
              width="64px"
              tabletWidth="80px"
              desktopWidth="120px"
              Icon={SadIcon}
            />
            <FlexColumn $gap="16px">
              <StyledH3 $weight="500">{koTitle}</StyledH3>
              <StyledP>{koBody}</StyledP>
            </FlexColumn>
            <AtomButton label="Torna indietro" onClick={onBack} />
          </>
        )}
      </StyledFormTyp>
    </BootstrapCols>
  )
}
