import { StyledH2, StyledH4 } from '../../commons/Theme'
import { StyledFormDelega, StyledFormText } from './FormDelegaRiscossione.style'
import { FormDeceduto } from './components/FormDeceduto'
import { FormDelegato } from './components/FormDelegato'
import { FormErede } from './components/FormErede'
import { FormFornitura } from './components/FormFornitura'

export const FormDelegaRiscossione = () => {
  return (
    <StyledFormDelega>
      <StyledH2>Delega alla riscossione ad un erede</StyledH2>
      <FormErede />
      <StyledFormText>
        <StyledH4 $weight="500">
          In qualità di erede/legatario del/la Signor/a
        </StyledH4>
        <p>Completa il form sottostante con i dati del de cuius</p>
      </StyledFormText>
      <FormDeceduto />
      <p>
        consapevole/i delle sanzioni penali richiamate dall'art. 76 del DPR
        445/2000 in caso di dichiarazioni mendaci e della decadenza dei benefici
        ottenuti sulla base di dichiarazioni non veritiere, ai sensi dell'art.
        75 del DPR 445/2000; informato/i che i dati resi sono prescritti dalle
        disposizioni vigenti per il procedimento per il quale sono richiesti e
        verranno utilizzati esclusivamente per tale scopo (art. 13 del Dlgs
        30/06/ 2003, n. 196);
      </p>
      <p>
        DICHIARO di delegare alla riscossione dei crediti relativi al contratto
        di fornitura spettanti al de cuius, manlevando A2A Energia S.p.A. da
        ogni responsabilità derivante dalla riscossione:
      </p>
      <FormFornitura />
      {/* <FormEredeReadOnly /> */}
      <FormDelegato />
    </StyledFormDelega>
  )
}
