import { useContext } from 'react'
import { Input } from '../../../commons/FormComponents/Input'
import { SelectField } from '../../../commons/FormComponents/SelectField'
import {
  StyledFormGrid,
  StyledFormText,
  FlexColumn,
  StyledEredeTitle,
} from '../FormDelegaRiscossione.style'
import { ErediContext } from '../context/ErediContext'
import { AtomButton } from '../../../atoms/AtomButton'
import { PlusIcon } from 'components/Icons/PlusIcon'
import { StyledH4, Colors } from '../../../commons/Theme'
import { TrashIcon } from 'components/Icons/TrashIcon'
import { Icon } from '../../Icons/Icon'
import {
  errorHandler,
  isCodiceFiscaleValidByNameSurname,
} from '../helpers/utils'
import { prepareStringForDataLayer } from '../../../commons/utils'
import { InputGeolabStreet, InputGeolabTown } from './InputGeolab'

export const parentelaOptions = [
  {
    label: 'Coniuge',
    value: 'coniuge',
  },
  {
    label: 'Fratello/Sorella',
    value: 'fratello/sorella',
  },
  {
    label: 'Figlio/a',
    value: 'figlio/a',
  },
  {
    label: 'Nessuno',
    value: 'nessuno',
  },
]

export const FormErede = () => {
  const { state, dispatch, errors, dispatchError, triggerValidation } =
    useContext(ErediContext)

  function handleDeleteErede(index: number) {
    dispatch({
      type: 'DELETE_EREDE',
      payload: { index: index },
    })
    dispatchError({
      type: 'CLEAN_ERRORS_WITH_KEY',
      payload: `erede-${index}`,
    })

    const eredeNomeValue = prepareStringForDataLayer(
      `${state.eredi[index]?.cognome} ${state.eredi[index]?.nome}`
    )
    const isDeletedRinunciatario = state.rinunciatari.findIndex(
      (el) => el?.value === eredeNomeValue
    )
    if (isDeletedRinunciatario > -1) {
      dispatch({
        type: 'DELETE_RINUNCIATARIO',
        payload: { index: isDeletedRinunciatario },
      })
    }
  }

  return (
    <FlexColumn $gap="24px">
      <FlexColumn $gap="40px">
        {state.eredi.map((erede, index) => (
          <FlexColumn $gap="40px" key={index}>
            <StyledFormText>
              {index === 0 ? (
                <>
                  <StyledH4 $weight="500">Io sottoscritto</StyledH4>
                  <p>
                    In qualità di erede, completa il form sottostante con i tuoi
                    dati personali
                  </p>
                </>
              ) : (
                <StyledEredeTitle>
                  <StyledH4 $weight="500">Erede {index + 1}</StyledH4>
                  <Icon
                    Icon={TrashIcon}
                    width="24px"
                    stroke={Colors.azure600}
                    hoverStroke={Colors.blue400}
                    onClick={() => {
                      handleDeleteErede(index)
                    }}
                  />
                </StyledEredeTitle>
              )}
            </StyledFormText>
            <StyledFormGrid>
              <Input
                id={`erede-${index}-nome`}
                label="Nome*"
                value={erede.nome}
                onChange={(value) =>
                  dispatch({
                    type: 'UPDATE_EREDE_VALUE',
                    payload: { index: index, value: { nome: value } },
                  })
                }
                onFocus={() => {
                  dispatchError({
                    type: 'UPDATE_ERROR_VALUE',
                    payload: {
                      [`erede-${index}-nome`]: '',
                    },
                  })
                }}
                error={errors[`erede-${index}-nome`] || ''}
                validate={() =>
                  dispatchError({
                    type: 'UPDATE_ERROR_VALUE',
                    payload: {
                      [`erede-${index}-nome`]: errorHandler('nome', erede.nome),
                    },
                  })
                }
                isValid={errors[`erede-${index}-nome`] === ''}
                validateOnBlur
                triggerValidation={triggerValidation}
              />
              <Input
                id={`erede-${index}-cognome`}
                label="Cognome*"
                value={erede.cognome}
                onChange={(value) =>
                  dispatch({
                    type: 'UPDATE_EREDE_VALUE',
                    payload: { index: index, value: { cognome: value } },
                  })
                }
                onFocus={() => {
                  dispatchError({
                    type: 'UPDATE_ERROR_VALUE',
                    payload: {
                      [`erede-${index}-cognome`]: '',
                    },
                  })
                }}
                error={errors[`erede-${index}-cognome`] || ''}
                isValid={errors[`erede-${index}-cognome`] === ''}
                validate={() =>
                  dispatchError({
                    type: 'UPDATE_ERROR_VALUE',
                    payload: {
                      [`erede-${index}-cognome`]: errorHandler(
                        'cognome',
                        erede.cognome
                      ),
                    },
                  })
                }
                validateOnBlur
                triggerValidation={triggerValidation}
              />
              <InputGeolabTown
                id={`erede-${index}-comune-nascita`}
                label="Comune di nascita*"
                value={erede.comuneNascita}
                onChange={(value) =>
                  dispatch({
                    type: 'UPDATE_EREDE_VALUE',
                    payload: { index: index, value: { comuneNascita: value } },
                  })
                }
                onSelect={(suggestion) => {
                  dispatch({
                    type: 'UPDATE_EREDE_VALUE',
                    payload: {
                      index: index,
                      value: { comuneNascita: suggestion['Des'] },
                    },
                  })
                  dispatch({
                    type: 'UPDATE_EREDE_VALUE',
                    payload: {
                      index: index,
                      value: { provinciaNascita: suggestion['Prov'] },
                    },
                  })
                  dispatchError({
                    type: 'UPDATE_ERROR_VALUE',
                    payload: {
                      [`erede-${index}-provincia-nascita`]: '',
                    },
                  })
                }}
                onFocus={() => {
                  dispatchError({
                    type: 'UPDATE_ERROR_VALUE',
                    payload: {
                      [`erede-${index}-comune-nascita`]: '',
                    },
                  })
                }}
                error={errors[`erede-${index}-comune-nascita`] || ''}
                isValid={errors[`erede-${index}-comune-nascita`] === ''}
                validate={() =>
                  dispatchError({
                    type: 'UPDATE_ERROR_VALUE',
                    payload: {
                      [`erede-${index}-comune-nascita`]: errorHandler(
                        'obbligatorio',
                        erede.comuneNascita
                      ),
                    },
                  })
                }
                validateOnBlur
                triggerValidation={triggerValidation}
              />
              <Input
                id={`erede-${index}-provincia-nascita`}
                label="Provincia*"
                value={erede.provinciaNascita}
                onChange={(value) =>
                  dispatch({
                    type: 'UPDATE_EREDE_VALUE',
                    payload: {
                      index: index,
                      value: { provinciaNascita: value },
                    },
                  })
                }
                onFocus={() => {
                  dispatchError({
                    type: 'UPDATE_ERROR_VALUE',
                    payload: {
                      [`erede-${index}-provincia-nascita`]: '',
                    },
                  })
                }}
                error={errors[`erede-${index}-provincia-nascita`] || ''}
                isValid={errors[`erede-${index}-provincia-nascita`] === ''}
                validate={() =>
                  dispatchError({
                    type: 'UPDATE_ERROR_VALUE',
                    payload: {
                      [`erede-${index}-provincia-nascita`]: errorHandler(
                        'obbligatorio',
                        erede.provinciaNascita
                      ),
                    },
                  })
                }
                validateOnBlur
                triggerValidation={triggerValidation}
              />
              <Input
                id={`erede-${index}-data-nascita`}
                label="Data di nascita*"
                value={erede.dataNascita}
                onChange={(value) =>
                  dispatch({
                    type: 'UPDATE_EREDE_VALUE',
                    payload: { index: index, value: { dataNascita: value } },
                  })
                }
                onFocus={() => {
                  dispatchError({
                    type: 'UPDATE_ERROR_VALUE',
                    payload: {
                      [`erede-${index}-data-nascita`]: '',
                    },
                  })
                }}
                error={errors[`erede-${index}-data-nascita`] || ''}
                isValid={errors[`erede-${index}-data-nascita`] === ''}
                validate={() =>
                  dispatchError({
                    type: 'UPDATE_ERROR_VALUE',
                    payload: {
                      [`erede-${index}-data-nascita`]: errorHandler(
                        'dataNascita',
                        erede.dataNascita
                      ),
                    },
                  })
                }
                placeholder="gg/mm/aaaa"
                validateOnBlur
                triggerValidation={triggerValidation}
              />
              <Input
                id={`erede-${index}-nazionalita`}
                label="Nazionalità*"
                value={erede.nazionalita}
                onChange={(value) =>
                  dispatch({
                    type: 'UPDATE_EREDE_VALUE',
                    payload: { index: index, value: { nazionalita: value } },
                  })
                }
                onFocus={() => {
                  dispatchError({
                    type: 'UPDATE_ERROR_VALUE',
                    payload: {
                      [`erede-${index}-nazionalita`]: '',
                    },
                  })
                }}
                error={errors[`erede-${index}-nazionalita`] || ''}
                isValid={errors[`erede-${index}-nazionalita`] === ''}
                validate={() =>
                  dispatchError({
                    type: 'UPDATE_ERROR_VALUE',
                    payload: {
                      [`erede-${index}-nazionalita`]: errorHandler(
                        'nazionalita',
                        erede.nazionalita
                      ),
                    },
                  })
                }
                validateOnBlur
                triggerValidation={triggerValidation}
              />
              <Input
                id={`erede-${index}-codice-fiscale`}
                label="Codice Fiscale*"
                value={erede.codiceFiscale}
                onChange={(value) =>
                  dispatch({
                    type: 'UPDATE_EREDE_VALUE',
                    payload: {
                      index: index,
                      value: { codiceFiscale: value.toLocaleUpperCase() },
                    },
                  })
                }
                onFocus={() => {
                  dispatchError({
                    type: 'UPDATE_ERROR_VALUE',
                    payload: {
                      [`erede-${index}-codice-fiscale`]: '',
                    },
                  })
                }}
                error={errors[`erede-${index}-codice-fiscale`] || ''}
                isValid={errors[`erede-${index}-codice-fiscale`] === ''}
                validate={() =>
                  dispatchError({
                    type: 'UPDATE_ERROR_VALUE',
                    payload: {
                      [`erede-${index}-codice-fiscale`]: !erede.codiceFiscale
                        ? 'Campo obbligatorio'
                        : !isCodiceFiscaleValidByNameSurname(
                              erede.codiceFiscale,
                              erede.nome,
                              erede.cognome
                            )
                          ? 'Codice fiscale errato'
                          : '',
                    },
                  })
                }
                validateOnBlur
                triggerValidation={triggerValidation}
              />
              <SelectField
                id={`erede-${index}-legame-parentela`}
                label="Legame di parentela con il de cuius*"
                placeholder="Seleziona un'opzione"
                options={parentelaOptions}
                value={erede.legameParentela}
                onChange={(value) =>
                  dispatch({
                    type: 'UPDATE_EREDE_VALUE',
                    payload: {
                      index: index,
                      value: { legameParentela: value },
                    },
                  })
                }
                onFocus={() => {
                  dispatchError({
                    type: 'UPDATE_ERROR_VALUE',
                    payload: {
                      [`erede-${index}-legame-parentela`]: '',
                    },
                  })
                }}
                error={errors[`erede-${index}-legame-parentela`] || ''}
                isValid={errors[`erede-${index}-legame-parentela`] === ''}
                validate={() =>
                  dispatchError({
                    type: 'UPDATE_ERROR_VALUE',
                    payload: {
                      [`erede-${index}-legame-parentela`]: errorHandler(
                        'obbligatorio',
                        erede.legameParentela?.value || ''
                      ),
                    },
                  })
                }
                validateOnBlur
                triggerValidation={triggerValidation}
              />
              <InputGeolabTown
                id={`erede-${index}-comune-residenza`}
                label="Comune di residenza*"
                value={erede.comuneResidenza}
                onChange={(value) =>
                  dispatch({
                    type: 'UPDATE_EREDE_VALUE',
                    payload: {
                      index: index,
                      value: { comuneResidenza: value },
                    },
                  })
                }
                onSelect={(suggestion) => {
                  dispatch({
                    type: 'UPDATE_EREDE_VALUE',
                    payload: {
                      index: index,
                      value: { comuneResidenza: suggestion.Des },
                    },
                  })
                  dispatch({
                    type: 'UPDATE_EREDE_VALUE',
                    payload: {
                      index: index,
                      value: { provinciaResidenza: suggestion.Prov },
                    },
                  })
                  dispatch({
                    type: 'UPDATE_EREDE_VALUE',
                    payload: {
                      index: index,
                      value: {
                        codiceResidenza: suggestion.TownKey || suggestion.Key,
                      },
                    },
                  })
                  dispatchError({
                    type: 'UPDATE_ERROR_VALUE',
                    payload: {
                      [`erede-${index}-provincia-residenza`]: '',
                    },
                  })
                }}
                onFocus={() => {
                  dispatchError({
                    type: 'UPDATE_ERROR_VALUE',
                    payload: {
                      [`erede-${index}-comune-residenza`]: '',
                    },
                  })
                }}
                error={errors[`erede-${index}-comune-residenza`] || ''}
                isValid={errors[`erede-${index}-comune-residenza`] === ''}
                validate={() =>
                  dispatchError({
                    type: 'UPDATE_ERROR_VALUE',
                    payload: {
                      [`erede-${index}-comune-residenza`]: errorHandler(
                        'obbligatorio',
                        erede.comuneResidenza
                      ),
                    },
                  })
                }
                validateOnBlur
                triggerValidation={triggerValidation}
              />
              <Input
                id={`erede-${index}-provincia-residenza`}
                label="Provincia*"
                value={erede.provinciaResidenza}
                onChange={(value) =>
                  dispatch({
                    type: 'UPDATE_EREDE_VALUE',
                    payload: {
                      index: index,
                      value: { provinciaResidenza: value },
                    },
                  })
                }
                onFocus={() => {
                  dispatchError({
                    type: 'UPDATE_ERROR_VALUE',
                    payload: {
                      [`erede-${index}-provincia-residenza`]: '',
                    },
                  })
                }}
                error={errors[`erede-${index}-provincia-residenza`] || ''}
                isValid={errors[`erede-${index}-provincia-residenza`] === ''}
                validate={() =>
                  dispatchError({
                    type: 'UPDATE_ERROR_VALUE',
                    payload: {
                      [`erede-${index}-provincia-residenza`]: errorHandler(
                        'obbligatorio',
                        erede.provinciaResidenza
                      ),
                    },
                  })
                }
                validateOnBlur
                triggerValidation={triggerValidation}
              />
              <InputGeolabStreet
                id={`erede-${index}-indirizzo-residenza`}
                label="Indirizzo di residenza*"
                value={erede.indirizzoResidenza}
                townKey={erede.codiceResidenza || ''}
                onChange={(value) =>
                  dispatch({
                    type: 'UPDATE_EREDE_VALUE',
                    payload: {
                      index: index,
                      value: { indirizzoResidenza: value },
                    },
                  })
                }
                onSelect={(value) => {
                  dispatch({
                    type: 'UPDATE_EREDE_VALUE',
                    payload: {
                      index: index,
                      value: {
                        indirizzoResidenza: `${value.Dug} ${value.Street}`,
                      },
                    },
                  })
                  dispatch({
                    type: 'UPDATE_EREDE_VALUE',
                    payload: {
                      index: index,
                      value: {
                        CAP: value.Zip,
                      },
                    },
                  })
                }}
                onFocus={() => {
                  dispatchError({
                    type: 'UPDATE_ERROR_VALUE',
                    payload: {
                      [`erede-${index}-indirizzo-residenza`]: '',
                    },
                  })
                }}
                error={errors[`erede-${index}-indirizzo-residenza`] || ''}
                isValid={errors[`erede-${index}-indirizzo-residenza`] === ''}
                validate={() =>
                  dispatchError({
                    type: 'UPDATE_ERROR_VALUE',
                    payload: {
                      [`erede-${index}-indirizzo-residenza`]: errorHandler(
                        'obbligatorio',
                        erede.indirizzoResidenza
                      ),
                    },
                  })
                }
                validateOnBlur
                triggerValidation={triggerValidation}
              />
              <Input
                id={`erede-${index}-numero-civico`}
                label="Numero civico*"
                value={erede.numeroCivico}
                onChange={(value) =>
                  dispatch({
                    type: 'UPDATE_EREDE_VALUE',
                    payload: { index: index, value: { numeroCivico: value } },
                  })
                }
                onFocus={() => {
                  dispatchError({
                    type: 'UPDATE_ERROR_VALUE',
                    payload: {
                      [`erede-${index}-numero-civico`]: '',
                    },
                  })
                }}
                error={errors[`erede-${index}-numero-civico`] || ''}
                isValid={errors[`erede-${index}-numero-civico`] === ''}
                validate={() =>
                  dispatchError({
                    type: 'UPDATE_ERROR_VALUE',
                    payload: {
                      [`erede-${index}-numero-civico`]: errorHandler(
                        'obbligatorio',
                        erede.numeroCivico
                      ),
                    },
                  })
                }
                validateOnBlur
                triggerValidation={triggerValidation}
              />
              {index === 0 && (
                <Input
                  id={`erede-${index}-telefono`}
                  label="Telefono*"
                  value={erede.telefono || ''}
                  onChange={(value) =>
                    dispatch({
                      type: 'UPDATE_EREDE_VALUE',
                      payload: { index: index, value: { telefono: value } },
                    })
                  }
                  onFocus={() => {
                    dispatchError({
                      type: 'UPDATE_ERROR_VALUE',
                      payload: {
                        [`erede-${index}-telefono`]: '',
                      },
                    })
                  }}
                  error={errors[`erede-${index}-telefono`] || ''}
                  isValid={errors[`erede-${index}-telefono`] === ''}
                  validate={() =>
                    dispatchError({
                      type: 'UPDATE_ERROR_VALUE',
                      payload: {
                        [`erede-${index}-telefono`]: errorHandler(
                          'telefono',
                          erede.telefono || ''
                        ),
                      },
                    })
                  }
                  validateOnBlur
                  triggerValidation={triggerValidation}
                />
              )}
            </StyledFormGrid>
          </FlexColumn>
        ))}
      </FlexColumn>
      {state.eredi.length < 5 && (
        <AtomButton
          label="Aggiungi erede"
          onClick={() =>
            dispatch({
              type: 'ADD_EREDE',
            })
          }
          styleType="ghost"
          icon={<PlusIcon />}
          iconPosition="right"
        />
      )}
    </FlexColumn>
  )
}

export const FormEredeReadOnly = () => {
  const { state } = useContext(ErediContext)

  return (
    <StyledFormGrid>
      <Input
        label="Nome*"
        value={state.eredi[0]?.nome || ''}
        onChange={() => {}}
        readOnly
      />
      <Input
        label="Cognome*"
        value={state.eredi[0]?.cognome || ''}
        onChange={() => {}}
        readOnly
      />
      <Input
        label="Comune di nascita*"
        value={state.eredi[0]?.comuneNascita || ''}
        onChange={() => {}}
        readOnly
      />
      <Input
        label="Provincia*"
        value={state.eredi[0]?.provinciaNascita || ''}
        onChange={() => {}}
        readOnly
      />
      <Input
        label="Data di nascita*"
        value={state.eredi[0]?.dataNascita || ''}
        onChange={() => {}}
        readOnly
      />
      <Input
        label="Nazionalità*"
        value={state.eredi[0]?.nazionalita || ''}
        onChange={() => {}}
        readOnly
      />
      <Input
        label="Codice Fiscale*"
        value={state.eredi[0]?.codiceFiscale || ''}
        onChange={() => {}}
        readOnly
      />
      <SelectField
        label="Legame di parentela con il de cuius*"
        placeholder="Seleziona un'opzione"
        options={[]}
        value={state.eredi[0]?.legameParentela || { value: '', label: '' }}
        onChange={() => {}}
        readOnly
      />
      <Input
        label="Comune di residenza*"
        value={state.eredi[0]?.comuneResidenza || ''}
        onChange={() => {}}
        readOnly
      />
      <Input
        label="Provincia*"
        value={state.eredi[0]?.provinciaResidenza || ''}
        onChange={() => {}}
        readOnly
      />
      <Input
        label="Indirizzo di residenza*"
        value={state.eredi[0]?.indirizzoResidenza || ''}
        onChange={() => {}}
        readOnly
      />
      <Input
        label="Numero civico*"
        value={state.eredi[0]?.numeroCivico || ''}
        onChange={() => {}}
        readOnly
      />
    </StyledFormGrid>
  )
}

export const ErediSecondariReadOnly = () => {
  const { state } = useContext(ErediContext)

  return (
    <>
      {state.eredi.slice(1).map((erede, index) => (
        <FlexColumn $gap="40px" key={index}>
          <StyledFormText>
            <StyledEredeTitle>
              <StyledH4 $weight="500">Erede {index + 2}</StyledH4>
            </StyledEredeTitle>
          </StyledFormText>
          <StyledFormGrid>
            <Input
              label="Nome*"
              value={erede.nome}
              onChange={() => {}}
              readOnly
            />
            <Input
              label="Cognome*"
              value={erede.cognome}
              onChange={() => {}}
              readOnly
            />
            <Input
              label="Comune di nascita*"
              value={erede.comuneNascita}
              onChange={() => {}}
              readOnly
            />
            <Input
              label="Provincia*"
              value={erede.provinciaNascita}
              onChange={() => {}}
              readOnly
            />
            <Input
              label="Data di nascita*"
              value={erede.dataNascita}
              onChange={() => {}}
              readOnly
            />
            <Input
              label="Nazionalità*"
              value={erede.nazionalita}
              onChange={() => {}}
              readOnly
            />
            <Input
              label="Codice Fiscale*"
              value={erede.codiceFiscale}
              onChange={() => {}}
              readOnly
            />
            <SelectField
              label="Legame di parentela con il de cuius*"
              placeholder="Seleziona un'opzione"
              options={[]}
              value={erede.legameParentela}
              onChange={() => {}}
              readOnly
            />
            <Input
              label="Comune di residenza*"
              value={erede.comuneResidenza}
              onChange={() => {}}
              readOnly
            />
            <Input
              label="Provincia*"
              value={erede.provinciaResidenza}
              onChange={() => {}}
              readOnly
            />
            <Input
              label="Indirizzo di residenza*"
              value={erede.indirizzoResidenza}
              onChange={() => {}}
              readOnly
            />
            <Input
              label="Numero civico*"
              value={erede.numeroCivico}
              onChange={() => {}}
              readOnly
            />
          </StyledFormGrid>
        </FlexColumn>
      ))}
    </>
  )
}
