import { Font, Text, View } from '@react-pdf/renderer'
import styles from './PDF.styled'
import { DelegaEredeData, EredeData } from './PDF.types'

const hyphenationCallback = (word: string) => {
  return [word]
}

Font.registerHyphenationCallback(hyphenationCallback)
const DelegaErede = ({ data }: { data: DelegaEredeData }) => {
  return (
    <View>
      <View>
        <Text style={styles.title}>DELEGA ALLA RISCOSSIONE AD UN EREDE</Text>
      </View>
      {data && (
        <View>
          <View style={styles.erediList}>
            {data.eredi.map((erede: EredeData, index: number) => (
              <View key={index} style={styles.eredeBlock}>
                <Text>
                  <Text style={styles.counter}>{index + 1}.</Text>
                  <Text> </Text>
                  <Text> </Text>
                  <Text>Io sottoscritto/a</Text>{' '}
                  <Text style={styles.dynamic}>
                    {erede.name} {erede.surname}
                  </Text>{' '}
                </Text>
                <Text>
                  nato/a a{' '}
                  <Text style={styles.dynamic}>{erede.birthplace}</Text>{' '}
                  Provincia{' '}
                  <Text style={styles.dynamic}>{erede.birthcountry}</Text> il{' '}
                  <Text style={styles.dynamic}>{erede.birthdate}</Text> Cod.
                  Fisc. <Text style={styles.dynamic}>{erede.fiscalcode}</Text>{' '}
                </Text>
                <Text>
                  residente a <Text style={styles.dynamic}>{erede.city}</Text>{' '}
                  Provincia <Text style={styles.dynamic}>{erede.country}</Text>{' '}
                  Via/piazza <Text style={styles.dynamic}>{erede.address}</Text>{' '}
                  n. <Text style={styles.dynamic}>{erede.number}</Text>
                </Text>
              </View>
            ))}
          </View>
          <View style={styles.eredeBlock}>
            <Text>
              In qualità di erede/legatario del/la Signor/a{' '}
              <Text style={styles.dynamic}>
                {data.death.name} {data.death.surname}
              </Text>{' '}
            </Text>
            <Text>
              Cod. Fisc.{' '}
              <Text style={styles.dynamic}>{data.death.fiscalcode}</Text> nato/a
              il <Text style={styles.dynamic}>{data.death.birthdate} </Text>a{' '}
              <Text style={styles.dynamic}>{data.death.birthplace},</Text>{' '}
            </Text>
            <Text>
              deceduto/a in data{' '}
              <Text style={styles.dynamic}>{data.death.deathdate}</Text>
            </Text>
          </View>
          <View style={styles.eredeBlock}>
            <Text style={styles.paragraph}>
              consapevole/i delle sanzioni penali richiamate dall’art. 76 del
              DPR 445/2000 in caso di dichiarazioni mendaci e della decadenza
              dei benefici ottenuti sulla base di dichiarazioni non veritiere,
              ai sensi dell’art. 75 del DPR 445/2000;
            </Text>
            <Text style={styles.paragraph}>
              informato/i che i dati resi sono prescritti dalle disposizioni
              vigenti per il procedimento per il quale sono richiesti e verranno
              utilizzati esclusivamente per tale scopo (art. 13 del Dlgs 30/06/
              2003, n. 196);
            </Text>
          </View>
          <View style={[styles.eredeDeclaration]}>
            <Text style={styles.subtitle}>DICHIARO</Text>
            <Text>
              <Text>
                di delegare alla riscossione dei crediti relativi al contratto
                di fornitura n.{' '}
                <Text style={styles.dynamic}>{data.delega.number}</Text>{' '}
              </Text>
              <Text>
                spettanti al de cuius, manlevando A2A Energia S.p.A. da ogni
                responsabilità derivante dalla riscossione,{' '}
              </Text>
            </Text>
            <Text>
              <Text>
                il/la Sig./Sig.ra{' '}
                <Text style={styles.dynamic}>
                  {data.delegated.name} {data.delegated.surname}
                </Text>{' '}
                <Text>
                  nato/a il{' '}
                  <Text style={styles.dynamic}>{data.delegated.birthdate}</Text>{' '}
                </Text>
                <Text>
                  a{' '}
                  <Text style={styles.dynamic}>
                    {data.delegated.birthplace}
                  </Text>{' '}
                </Text>
                <Text>
                  Provincia{' '}
                  <Text style={styles.dynamic}>
                    {data.delegated.birthcountry}
                  </Text>{' '}
                </Text>
              </Text>
            </Text>
            <Text>
              <Text>
                Cod. Fisc.{' '}
                <Text style={styles.dynamic}>{data.delegated.fiscalcode}</Text>
              </Text>{' '}
              <Text>
                residente a{' '}
                <Text style={styles.dynamic}>{data.delegated.city}</Text>
              </Text>{' '}
              <Text>
                <Text>
                  Provincia{' '}
                  <Text style={styles.dynamic}>{data.delegated.country}</Text>
                </Text>{' '}
                <Text>
                  Via/piazza{' '}
                  <Text style={styles.dynamic}>{data.delegated.address}</Text>
                </Text>{' '}
                <Text>
                  n <Text style={styles.dynamic}>{data.delegated.number}</Text>
                </Text>
                <Text>, che accetta.</Text>
              </Text>
            </Text>
          </View>
          <Text style={styles.disclaimer}>
            Firma dei dichiaranti{' '}
            <Text style={styles.disclaimerInner}>
              (compreso il soggetto delegato):
            </Text>
          </Text>
          <View style={styles.erediSigns}>
            {data.eredi.map((erede: EredeData, index: number) => (
              <View key={index} style={styles.eredeSignBlock} wrap={false}>
                <View style={[styles.eredeSignBlockChild, { minWidth: '50%' }]}>
                  <Text>{index + 1}.</Text>
                  <Text> </Text>
                  <Text> </Text>
                  <Text>Sig./Sig.ra </Text>
                  <Text style={styles.dynamic}>
                    {erede.name} {erede.surname}{' '}
                  </Text>
                </View>
                <View style={[styles.eredeSignBlockChild, { maxWidth: '50%' }]}>
                  <Text>Firma __________________________________</Text>
                </View>
              </View>
            ))}
            <View style={styles.eredeSignBlock} wrap={false}>
              <View style={[styles.eredeSignBlockChild, { minWidth: '50%' }]}>
                <Text>
                  <Text style={styles.bold}>Soggetto delegato</Text> Sig./Sig.ra{' '}
                  <Text style={styles.dynamic}>
                    {data.delegated.name} {data.delegated.surname}
                  </Text>{' '}
                </Text>
              </View>
              <View style={[styles.eredeSignBlockChild, { maxWidth: '50%' }]}>
                <Text>Firma __________________________________</Text>
              </View>
            </View>
          </View>
        </View>
      )}
      <View wrap={false}>
        <Text style={styles.paragraph}>
          In conformità all’Art. 38.3 del D.P.R. 28/12/2000 N. 445 la presente è
          sottoscritta e presentata unitamente a copia fotostatica non
          autenticata di un documento d’identità in corso di validità di ciascun
          sottoscrittore.
        </Text>
        <View style={styles.footer}>
          <Text>Modulo predisposto da A2A Energia</Text>
        </View>
      </View>
    </View>
  )
}

export default DelegaErede
