import { A2ADataContextProvider } from 'contexts/A2ADataContext'
import Layout from '../../../../../Layout'
import Breadcrumbs from '../../../../../components/Breadcrumbs/Breadcrumbs'
import { StyledErediContainer } from '../../../../../pages-style/eredi.style'
import { FormEredi } from '../../../../../components/Eredi/FormEredi'

export default function FormErediPage() {
  return (
    <A2ADataContextProvider>
      <Layout pageTitle="Delega alla riscossione ad un erede">
        <Breadcrumbs
          links={[
            { title: 'Assistenza', uri: '/assistenza' },
            { title: 'Modulistica', uri: '/assistenza/modulistica' },
            {
              title: 'Altri documenti',
              uri: '/assistenza/modulistica/altri-moduli',
            },
            {
              title: 'Altri moduli',
              uri: '/assistenza/modulistica/altri-documenti/altri-moduli',
            },
            {
              title:
                "Delega alla riscossione ad un erede e dichiarazione sostitutiva dell'atto di notorietà per erede",
              uri: '',
            },
          ]}
        />
        <StyledErediContainer>
          <FormEredi />
        </StyledErediContainer>
      </Layout>
    </A2ADataContextProvider>
  )
}
