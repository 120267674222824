import styled from 'styled-components'
import { BPSizes, Colors } from '../../../commons/Theme'
import { Icon } from '../../Icons/Icon'
import { InfoIcon } from '../../Icons/InfoIcon'

const StyledAvvisoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: ${Colors.blue100};
  border: 1px solid ${Colors.blue200};
  border-radius: 8px;
  padding: 16px;
  @media screen and (min-width: ${BPSizes.tablet}px) {
    gap: 16px;
    padding: 24px;
    flex-direction: row;
  }
`

const StyledAvvisoTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: ${Colors.grey900};
  line-height: 1.3;
  margin: 0;
  @media screen and (min-width: ${BPSizes.tablet}px) {
    font-size: 16px;
  }
  @media screen and (min-width: ${BPSizes.desktop}px) {
    font-size: 18px;
    line-height: 1.35;
  }
`

const StyledAvvisoMessage = styled(StyledAvvisoTitle)`
  font-weight: 400;
`

export const Avviso = ({
  title,
  message,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  title?: string
  message?: string
}) => {
  return (
    <StyledAvvisoWrapper {...rest}>
      <Icon width="24px" stroke={Colors.azure600} Icon={InfoIcon} />
      <div style={{ flex: 1 }}>
        {title && <StyledAvvisoTitle>{title}</StyledAvvisoTitle>}
        {message && <StyledAvvisoMessage>{message}</StyledAvvisoMessage>}
      </div>
    </StyledAvvisoWrapper>
  )
}
