import { StyleSheet } from '@react-pdf/renderer'

export default StyleSheet.create({
  page: {
    fontFamily: 'Times-Roman',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    padding: '50 50 20 50',
    fontSize: 10,
    lineHeight: 1.8,
  },
  title: {
    fontFamily: 'Times-Bold',
    fontSize: 14,
    marginBottom: 0,
    textAlign: 'center',
  },
  subtitle: {
    fontFamily: 'Times-Bold',
    fontSize: 10,
    marginBottom: 2,
    textAlign: 'center',
  },
  counter: {
    fontFamily: 'Times-Bold',
    fontSize: 11,
    paddingRight: 10,
  },
  dynamic: {
    fontFamily: 'Times-BoldItalic',
  },
  eredeBlock: {
    marginBottom: 4,
  },
  eredeSignBlock: {
    marginBottom: 1.4,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  eredeSignBlockChild: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  eredeDeclaration: {
    marginTop: 10,
    marginBottom: 4,
    fontFamily: 'Times-Bold',
  },
  disclaimer: {
    marginBottom: 4,
    fontFamily: 'Times-Bold',
    textAlign: 'center',
    textDecoration: 'underline',
  },
  disclaimerInner: {
    fontFamily: 'Times-Roman',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  listDot: {
    paddingLeft: 20,
    fontSize: 15,
    position: 'relative',
    top: -2,
  },
  listContent: {
    paddingLeft: 10,
    lineHeight: 1.25,
  },
  listPadding: {
    paddingLeft: 15,
  },
  paddingLeft: {
    paddingLeft: 22,
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkboxWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkbox: {
    position: 'relative',
    top: -3,
    width: 9,
    height: 9,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'black',
    marginRight: 6,
  },
  checkboxChecked: {
    backgroundColor: 'black',
  },
  bold: {
    fontFamily: 'Times-Bold',
  },
  italic: {
    fontFamily: 'Times-Italic',
  },
  underline: {
    textDecoration: 'underline',
  },
  erediList: {
    marginBottom: 8,
  },
  erediSigns: {
    marginBottom: 8,
  },
  heirs: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  heirsRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    marginBottom: -1,
  },
  heirsCell: {
    width: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '4 0 0 6',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'black',
  },
  heirsCellData: {
    width: '100%',
    borderLeftWidth: 0,
    padding: '4 10 0',
  },
  paragraph: {
    textAlign: 'justify',
    lineHeight: 'auto',
  },
  footer: {
    textAlign: 'right',
    fontSize: 8,
    marginVertical: 10,
  },
})
