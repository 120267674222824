import { useState } from 'react'
import { AtomButton } from '../../../atoms/AtomButton'
import { BootstrapCols } from '../../../commons/BootstrapCols'
import { FileDrop, CustomFile } from '../../../commons/FormComponents/FileDrop'
import { Colors, StyledH2 } from '../../../commons/Theme'
import {
  capitalizeFirstLetterWords,
  createFileEntity,
  createZip,
  snakeCase,
} from '../../../commons/utils'
import { Text } from '../../Webform/Webform.style'
import { FlexColumn } from '../FormDelegaRiscossione.style'
import { TYPType } from '../FormErediMain'
import { ErediContextState } from '../context/ErediContext'
import { uploadErediFile } from '../helpers/utils'
import { FormTYP } from './FormTYP'

type ErediFile = {
  erede0: CustomFile[]
  erede1: CustomFile[]
  erede2: CustomFile[]
  erede3: CustomFile[]
  erede4: CustomFile[]
}

type ErediError = {
  erede0: string
  erede1: string
  erede2: string
  erede3: string
  erede4: string
}

const initialErediFile = {
  erede0: [],
  erede1: [],
  erede2: [],
  erede3: [],
  erede4: [],
}

const initialErediError = {
  erede0: '',
  erede1: '',
  erede2: '',
  erede3: '',
  erede4: '',
}

export const FormCaricaFile = ({
  data,
  showLoader,
  hideLoader,
}: {
  data: ErediContextState
  showLoader: () => void
  hideLoader: () => void
}) => {
  const [delegaFile, setDelegaFile] = useState<CustomFile[]>([])
  const [certificatoFile, setCertificatoFile] = useState<CustomFile[]>([])
  const [testamentoFile, setTestamentoFile] = useState<CustomFile[]>([])
  const [delegatoFile, setDelegatoFile] = useState<CustomFile[]>([])
  const [erediFile, setErediFile] = useState<ErediFile>(initialErediFile)

  const [delegaError, setDelegaError] = useState<string>('')
  const [certificatoError, setCertificatoError] = useState<string>('')
  const [testamentoError, setTestamentoError] = useState<string>('')
  const [delegatoError, setDelegatoError] = useState<string>('')
  const [erediError, setErediError] = useState<ErediError>(initialErediError)

  const [showTYP, setShowTYP] = useState<TYPType>(null)

  function handleOnClick() {
    // Documento delega
    const errorDelega = delegaFile.length === 0 ? 'Campo obbligatorio' : ''
    // Certificato di morte
    const errorCertificato =
      certificatoFile.length === 0 ? 'Campo obbligatorio' : ''
    // Testamento
    const errorTestamento =
      data.testamento?.includes('si') && testamentoFile.length === 0
        ? 'Campo obbligatorio'
        : ''
    // Delegato
    const errorDelegato =
      data.delegatoIsSottoscritto === false && delegatoFile.length === 0
        ? 'Campo obbligatorio'
        : ''
    // Eredi
    let erediError = false
    data.eredi.forEach((_, index) => {
      if (
        //@ts-expect-error It should be improved
        erediFile[`erede${index}`] &&
        //@ts-expect-error It should be improved
        erediFile[`erede${index}`].length === 0
      ) {
        erediError = true
        setErediError((prev) => ({
          ...prev,
          [`erede${index}`]: 'Campo obbligatorio',
        }))
      }
    })

    setDelegaError(errorDelega)
    setCertificatoError(errorCertificato)
    setTestamentoError(errorTestamento)
    setDelegatoError(errorDelegato)

    if (!errorDelega && !errorCertificato && !errorTestamento && !erediError) {
      handleOnSubmit()
    }
  }

  async function handleOnSubmit() {
    showLoader()

    const bundleFiles = [
      ...Array.from(delegaFile || []),
      ...Array.from(certificatoFile || []),
      ...Array.from(testamentoFile || []),
      ...Array.from(delegatoFile || []),
      ...Array.from(Object.values(erediFile).flat(1)),
    ]
    const fileNames = bundleFiles.map((file) => file.name)
    const RAW = await createZip(bundleFiles)
    const fileId = await createFileEntity({
      base64: RAW || '',
      fileName: 'archive.zip',
      mimeType: 'application/zip',
      directoryPath: 'private://moduli-eredi/documents',
    })

    const params = new URLSearchParams(window.location.search)
    const token = params.get('code')

    if (fileId && token && fileNames.length > 0) {
      const response = await uploadErediFile(token, String(fileId), fileNames)
      if (response.status) {
        setShowTYP('ok')
      } else {
        console.error(
          `Error while posting files to a2a-api/eredi/update?_format=json`,
          { token, fileId, fileNames }
        )
        setShowTYP('ko')
      }
    } else {
      setShowTYP('ko')
    }

    hideLoader()
  }

  if (showTYP)
    return (
      <FormTYP
        type={showTYP}
        onBack={() => setShowTYP(null)}
        okTitle="File inviati con successo!"
        okBody={<>Troverai nella tua mail il riepilogo della tua richiesta.</>}
        koTitle="Qualcosa è andato storto!"
        koBody={<>C'è stato un errore nell'invio dei file.</>}
      />
    )

  return (
    <FlexColumn $gap="40px">
      <StyledH2>Carica i tuoi file</StyledH2>
      <FlexColumn $gap="16px">
        <Text $size={16} $color={Colors.grey700}>
          Firma la delega e la dichiarazione che hai ricevuto via mail e carica
          qui il file*
        </Text>
        <FileDrop
          maxSize={2000000}
          autoRename={`${snakeCase(data.fornitura)}_delega_`}
          maxFiles={1}
          onChange={(e) => {
            setDelegaFile(e)
            setDelegaError('')
          }}
          error={delegaError}
        />
      </FlexColumn>
      {data.eredi.map((erede, index) => (
        <FlexColumn $gap="16px" key={index}>
          <Text $size={16} $color={Colors.grey700}>
            Carica il documento di identità di{' '}
            {capitalizeFirstLetterWords(erede.nome)}{' '}
            {capitalizeFirstLetterWords(erede.cognome)}*
          </Text>
          <FileDrop
            maxSize={2000000}
            autoRename={`${snakeCase(data.fornitura)}_documento_${index + 1}_`}
            maxFiles={2}
            onChange={(e) => {
              setErediFile((prev) => ({ ...prev, [`erede${index}`]: e }))
              setErediError((prev) => ({ ...prev, [`erede${index}`]: '' }))
            }}
            //@ts-expect-error It should be improved
            error={erediError[`erede${index}`]}
          />
        </FlexColumn>
      ))}
      {data.delegatoIsSottoscritto === false && (
        <FlexColumn $gap="16px">
          <Text $size={16} $color={Colors.grey700}>
            Carica il documento di identità di{' '}
            {capitalizeFirstLetterWords(data.delegato.nome)}{' '}
            {capitalizeFirstLetterWords(data.delegato.cognome)}*
          </Text>
          <FileDrop
            maxSize={2000000}
            autoRename={`${snakeCase(data.fornitura)}_documento_delegato_`}
            maxFiles={1}
            onChange={(e) => {
              setDelegatoFile(e)
              setDelegatoError('')
            }}
            error={delegatoError}
          />
        </FlexColumn>
      )}
      <FlexColumn $gap="16px">
        <Text $size={16} $color={Colors.grey700}>
          Carica il certificato di morte*
        </Text>
        <FileDrop
          maxSize={2000000}
          autoRename={`${snakeCase(data.fornitura)}_certificato_morte_`}
          maxFiles={1}
          onChange={(e) => {
            setCertificatoFile(e)
            setCertificatoError('')
          }}
          error={certificatoError}
        />
      </FlexColumn>
      {data.testamento?.includes('si') && (
        <FlexColumn $gap="16px">
          <Text $size={16} $color={Colors.grey700}>
            Testamento*
          </Text>
          <FileDrop
            maxSize={2000000}
            autoRename={`${snakeCase(data.fornitura)}_testamento_`}
            maxFiles={1}
            onChange={(e) => {
              setTestamentoFile(e)
              setTestamentoError('')
            }}
            error={testamentoError}
          />
        </FlexColumn>
      )}
      <BootstrapCols
        style={{ margin: '0 auto 0 0' }}
        cols={12}
        tabletCols={4}
        desktopCols={3}
        largeCols={2}
      >
        <AtomButton
          style={{ width: '100%', justifyContent: 'center' }}
          label="Invia file"
          onClick={handleOnClick}
        />
      </BootstrapCols>
    </FlexColumn>
  )
}
