import { useContext } from 'react'
import { Checkbox } from '../../../commons/FormComponents/Checkbox'
import { Input } from '../../../commons/FormComponents/Input'
import { SelectField } from '../../../commons/FormComponents/SelectField'
import { FlexColumn, StyledFormGrid } from '../FormDelegaRiscossione.style'
import { ErediContext } from '../context/ErediContext'
import {
  errorHandler,
  isCodiceFiscaleValidByNameSurname,
} from '../helpers/utils'
import { FormEredeReadOnly, parentelaOptions } from './FormErede'
import { InputGeolabStreet, InputGeolabTown } from './InputGeolab'
export const FormDelegato = () => {
  const { state, errors, dispatch, dispatchError, triggerValidation } =
    useContext(ErediContext)

  return (
    <FlexColumn $gap="40px">
      <Checkbox
        defaultValue={true}
        field="delegato-sottoscritto"
        label="Il delegato corrisponde al sottoscritto"
        onChange={(value) => {
          {
            dispatch({ type: 'UPDATE_DELEGATO_TYPE', payload: value })
            if (value === true) {
              dispatchError({
                type: 'CLEAN_ERRORS_WITH_KEY',
                payload: 'delegato-',
              })
            }
          }
        }}
      />
      {state.delegatoIsSottoscritto ? (
        <FormEredeReadOnly />
      ) : (
        <StyledFormGrid>
          <Input
            id={`delegato-nome`}
            label="Nome*"
            value={state.delegato.nome}
            onChange={(value) =>
              dispatch({
                type: 'UPDATE_DELEGATO_VALUE',
                payload: { nome: value },
              })
            }
            onFocus={() => {
              dispatchError({
                type: 'UPDATE_ERROR_VALUE',
                payload: {
                  [`delegato-nome`]: '',
                },
              })
            }}
            error={errors[`delegato-nome`] || ''}
            isValid={errors[`delegato-nome`] === ''}
            validate={() =>
              dispatchError({
                type: 'UPDATE_ERROR_VALUE',
                payload: {
                  [`delegato-nome`]: errorHandler('nome', state.delegato.nome),
                },
              })
            }
            validateOnBlur
            triggerValidation={triggerValidation}
          />
          <Input
            id={`delegato-cognome`}
            label="Cognome*"
            value={state.delegato.cognome}
            onChange={(value) =>
              dispatch({
                type: 'UPDATE_DELEGATO_VALUE',
                payload: { cognome: value },
              })
            }
            onFocus={() => {
              dispatchError({
                type: 'UPDATE_ERROR_VALUE',
                payload: {
                  [`delegato-cognome`]: '',
                },
              })
            }}
            error={errors[`delegato-cognome`] || ''}
            isValid={errors[`delegato-cognome`] === ''}
            validate={() =>
              dispatchError({
                type: 'UPDATE_ERROR_VALUE',
                payload: {
                  [`delegato-cognome`]: errorHandler(
                    'cognome',
                    state.delegato.cognome
                  ),
                },
              })
            }
            validateOnBlur
            triggerValidation={triggerValidation}
          />
          <InputGeolabTown
            id={`delegato-comune-nascita`}
            label="Comune di nascita*"
            value={state.delegato.comuneNascita}
            onChange={(value) =>
              dispatch({
                type: 'UPDATE_DELEGATO_VALUE',
                payload: { comuneNascita: value },
              })
            }
            onSelect={(suggestion) => {
              dispatch({
                type: 'UPDATE_DELEGATO_VALUE',
                payload: { comuneNascita: suggestion['Des'] },
              })
              dispatch({
                type: 'UPDATE_DELEGATO_VALUE',
                payload: { provinciaNascita: suggestion['Prov'] },
              })
              dispatchError({
                type: 'UPDATE_ERROR_VALUE',
                payload: {
                  [`delegato-provincia-nascita`]: '',
                },
              })
            }}
            onFocus={() => {
              dispatchError({
                type: 'UPDATE_ERROR_VALUE',
                payload: {
                  [`delegato-comune-nascita`]: '',
                },
              })
            }}
            error={errors[`delegato-comune-nascita`] || ''}
            isValid={errors[`delegato-comune-nascita`] === ''}
            validate={() =>
              dispatchError({
                type: 'UPDATE_ERROR_VALUE',
                payload: {
                  [`delegato-comune-nascita`]: errorHandler(
                    'obbligatorio',
                    state.delegato.comuneNascita
                  ),
                },
              })
            }
            validateOnBlur
            triggerValidation={triggerValidation}
          />
          <Input
            id={`delegato-provincia-nascita`}
            label="Provincia*"
            value={state.delegato.provinciaNascita}
            onChange={(value) =>
              dispatch({
                type: 'UPDATE_DELEGATO_VALUE',
                payload: { provinciaNascita: value },
              })
            }
            onFocus={() => {
              dispatchError({
                type: 'UPDATE_ERROR_VALUE',
                payload: {
                  [`delegato-provincia-nascita`]: '',
                },
              })
            }}
            error={errors[`delegato-provincia-nascita`] || ''}
            isValid={errors[`delegato-provincia-nascita`] === ''}
            validate={() =>
              dispatchError({
                type: 'UPDATE_ERROR_VALUE',
                payload: {
                  [`delegato-provincia-nascita`]: errorHandler(
                    'obbligatorio',
                    state.delegato.provinciaNascita
                  ),
                },
              })
            }
            validateOnBlur
            triggerValidation={triggerValidation}
          />
          <Input
            id={`delegato-data-nascita`}
            label="Data di nascita*"
            value={state.delegato.dataNascita}
            onChange={(value) =>
              dispatch({
                type: 'UPDATE_DELEGATO_VALUE',
                payload: { dataNascita: value },
              })
            }
            onFocus={() => {
              dispatchError({
                type: 'UPDATE_ERROR_VALUE',
                payload: {
                  [`delegato-data-nascita`]: '',
                },
              })
            }}
            error={errors[`delegato-data-nascita`] || ''}
            isValid={errors[`delegato-data-nascita`] === ''}
            validate={() =>
              dispatchError({
                type: 'UPDATE_ERROR_VALUE',
                payload: {
                  [`delegato-data-nascita`]: errorHandler(
                    'dataNascita',
                    state.delegato.dataNascita
                  ),
                },
              })
            }
            placeholder="gg/mm/aaaa"
            validateOnBlur
            triggerValidation={triggerValidation}
          />
          <Input
            id={`delegato-nazionalita`}
            label="Nazionalità*"
            value={state.delegato.nazionalita}
            onChange={(value) =>
              dispatch({
                type: 'UPDATE_DELEGATO_VALUE',
                payload: { nazionalita: value },
              })
            }
            onFocus={() => {
              dispatchError({
                type: 'UPDATE_ERROR_VALUE',
                payload: {
                  [`delegato-nazionalita`]: '',
                },
              })
            }}
            error={errors[`delegato-nazionalita`] || ''}
            isValid={errors[`delegato-nazionalita`] === ''}
            validate={() =>
              dispatchError({
                type: 'UPDATE_ERROR_VALUE',
                payload: {
                  [`delegato-nazionalita`]: errorHandler(
                    'nazionalita',
                    state.delegato.nazionalita
                  ),
                },
              })
            }
            validateOnBlur
            triggerValidation={triggerValidation}
          />
          <Input
            id={`delegato-codice-fiscale`}
            label="Codice Fiscale*"
            value={state.delegato.codiceFiscale}
            onChange={(value) =>
              dispatch({
                type: 'UPDATE_DELEGATO_VALUE',
                payload: { codiceFiscale: value.toLocaleUpperCase() },
              })
            }
            onFocus={() => {
              dispatchError({
                type: 'UPDATE_ERROR_VALUE',
                payload: {
                  [`delegato-codice-fiscale`]: '',
                },
              })
            }}
            error={errors[`delegato-codice-fiscale`] || ''}
            isValid={errors[`delegato-codice-fiscale`] === ''}
            validate={() =>
              dispatchError({
                type: 'UPDATE_ERROR_VALUE',
                payload: {
                  [`delegato-codice-fiscale`]: !state.delegato.codiceFiscale
                    ? 'Campo obbligatorio'
                    : !isCodiceFiscaleValidByNameSurname(
                          state.delegato.codiceFiscale,
                          state.delegato.nome,
                          state.delegato.cognome
                        )
                      ? 'Codice fiscale errato'
                      : '',
                },
              })
            }
            validateOnBlur
            triggerValidation={triggerValidation}
          />
          <SelectField
            id={`delegato-legame-parentela`}
            label="Legame di parentela con il de cuius*"
            placeholder="Seleziona un'opzione"
            options={parentelaOptions}
            value={state.delegato.legameParentela}
            onChange={(value) =>
              dispatch({
                type: 'UPDATE_DELEGATO_VALUE',
                payload: { legameParentela: value },
              })
            }
            onFocus={() => {
              dispatchError({
                type: 'UPDATE_ERROR_VALUE',
                payload: {
                  [`delegato-legame-parentela`]: '',
                },
              })
            }}
            error={errors[`delegato-legame-parentela`] || ''}
            isValid={errors[`delegato-legame-parentela`] === ''}
            validate={() =>
              dispatchError({
                type: 'UPDATE_ERROR_VALUE',
                payload: {
                  [`delegato-legame-parentela`]: errorHandler(
                    'obbligatorio',
                    state.delegato.legameParentela?.value || ''
                  ),
                },
              })
            }
            validateOnBlur
            triggerValidation={triggerValidation}
          />
          <InputGeolabTown
            id={`delegato-comune-residenza`}
            label="Comune di residenza*"
            value={state.delegato.comuneResidenza}
            onChange={(value) =>
              dispatch({
                type: 'UPDATE_DELEGATO_VALUE',
                payload: { comuneResidenza: value },
              })
            }
            onSelect={(suggestion) => {
              dispatch({
                type: 'UPDATE_DELEGATO_VALUE',
                payload: { comuneResidenza: suggestion['Des'] },
              })
              dispatch({
                type: 'UPDATE_DELEGATO_VALUE',
                payload: { provinciaResidenza: suggestion['Prov'] },
              })
              dispatch({
                type: 'UPDATE_DELEGATO_VALUE',
                payload: {
                  codiceResidenza: suggestion.TownKey || suggestion.Key,
                },
              })
              dispatchError({
                type: 'UPDATE_ERROR_VALUE',
                payload: {
                  [`delegato-provincia-residenza`]: '',
                },
              })
            }}
            onFocus={() => {
              dispatchError({
                type: 'UPDATE_ERROR_VALUE',
                payload: {
                  [`delegato-comune-residenza`]: '',
                },
              })
            }}
            error={errors[`delegato-comune-residenza`] || ''}
            isValid={errors[`delegato-comune-residenza`] === ''}
            validate={() =>
              dispatchError({
                type: 'UPDATE_ERROR_VALUE',
                payload: {
                  [`delegato-comune-residenza`]: errorHandler(
                    'obbligatorio',
                    state.delegato.comuneResidenza
                  ),
                },
              })
            }
            validateOnBlur
            triggerValidation={triggerValidation}
          />
          <Input
            id={`delegato-provincia-residenza`}
            label="Provincia*"
            value={state.delegato.provinciaResidenza}
            onChange={(value) =>
              dispatch({
                type: 'UPDATE_DELEGATO_VALUE',
                payload: { provinciaResidenza: value },
              })
            }
            onFocus={() => {
              dispatchError({
                type: 'UPDATE_ERROR_VALUE',
                payload: {
                  [`delegato-provincia-residenza`]: '',
                },
              })
            }}
            error={errors[`delegato-provincia-residenza`] || ''}
            isValid={errors[`delegato-provincia-residenza`] === ''}
            validate={() =>
              dispatchError({
                type: 'UPDATE_ERROR_VALUE',
                payload: {
                  [`delegato-provincia-residenza`]: errorHandler(
                    'obbligatorio',
                    state.delegato.provinciaResidenza
                  ),
                },
              })
            }
            validateOnBlur
            triggerValidation={triggerValidation}
          />
          <InputGeolabStreet
            id={`delegato-indirizzo-residenza`}
            label="Indirizzo di residenza*"
            value={state.delegato.indirizzoResidenza}
            townKey={state.delegato.codiceResidenza || ''}
            onChange={(value) =>
              dispatch({
                type: 'UPDATE_DELEGATO_VALUE',
                payload: { indirizzoResidenza: value },
              })
            }
            onSelect={(suggestion) => {
              dispatch({
                type: 'UPDATE_DELEGATO_VALUE',
                payload: {
                  indirizzoResidenza: `${suggestion.Dug} ${suggestion.Street}`,
                },
              })
              dispatch({
                type: 'UPDATE_DELEGATO_VALUE',
                payload: {
                  CAP: suggestion.Zip,
                },
              })
            }}
            onFocus={() => {
              dispatchError({
                type: 'UPDATE_ERROR_VALUE',
                payload: {
                  [`delegato-indirizzo-residenza`]: '',
                },
              })
            }}
            error={errors[`delegato-indirizzo-residenza`] || ''}
            isValid={errors[`delegato-indirizzo-residenza`] === ''}
            validate={() =>
              dispatchError({
                type: 'UPDATE_ERROR_VALUE',
                payload: {
                  [`delegato-indirizzo-residenza`]: errorHandler(
                    'obbligatorio',
                    state.delegato.indirizzoResidenza
                  ),
                },
              })
            }
            validateOnBlur
            triggerValidation={triggerValidation}
          />
          <Input
            id={`delegato-numero-civico`}
            label="Numero civico*"
            value={state.delegato.numeroCivico}
            onChange={(value) =>
              dispatch({
                type: 'UPDATE_DELEGATO_VALUE',
                payload: { numeroCivico: value },
              })
            }
            onFocus={() => {
              dispatchError({
                type: 'UPDATE_ERROR_VALUE',
                payload: {
                  [`delegato-numero-civico`]: '',
                },
              })
            }}
            error={errors[`delegato-numero-civico`] || ''}
            isValid={errors[`delegato-numero-civico`] === ''}
            validate={() =>
              dispatchError({
                type: 'UPDATE_ERROR_VALUE',
                payload: {
                  [`delegato-numero-civico`]: errorHandler(
                    'obbligatorio',
                    state.delegato.numeroCivico
                  ),
                },
              })
            }
            validateOnBlur
            triggerValidation={triggerValidation}
          />
        </StyledFormGrid>
      )}
    </FlexColumn>
  )
}
