import { useEffect, useState } from 'react'
import {
  StyledCheckbox,
  StyledCheckboxLabel,
  StyledCheckboxWrapper,
  StyledErrorLabel,
} from './Checkbox.style'
import { Icon } from 'components/Icons/Icon'

export const Checkbox = ({
  field,
  label,
  disabled = false,
  defaultValue = false,
  error = false,
  errorMessage,
  reverse = false,
  onChange,
  onClick,
  triggerValidation,
  validate,
  icon,
  iconWidth,
}: CheckboxProps) => {
  const [checked, setChecked] = useState<boolean>(defaultValue)

  useEffect(() => {
    onChange(checked)
  }, [checked])

  useEffect(() => {
    if (triggerValidation) validate && validate()
  }, [triggerValidation])

  return (
    <div style={{ position: 'relative' }}>
      <StyledCheckboxWrapper $disabled={disabled}>
        {!reverse ? (
          <>
            <div className="checkbox-label__wrapper">
              <StyledCheckbox
                type="checkbox"
                id={field}
                disabled={disabled}
                checked={checked}
                onChange={() => setChecked((prev) => !prev)}
                onClick={() => {
                  if (onClick) {
                    onClick()
                  }
                }}
                $error={error}
              />
              <StyledCheckboxLabel
                htmlFor={field}
                dangerouslySetInnerHTML={{ __html: label ?? '' }}
                $disabled={disabled}
              ></StyledCheckboxLabel>
            </div>
            {icon && (
              // eslint-disable-next-line jsx-a11y/label-has-associated-control
              <label className="checkbox-icon__wrapper" htmlFor={field}>
                <Icon
                  Icon={icon}
                  width={`${iconWidth ?? 18}px`}
                  stroke="inherit"
                />
              </label>
            )}
          </>
        ) : (
          <>
            <div className="checkbox-label__wrapper">
              {icon && (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label className="checkbox-icon__wrapper" htmlFor={field}>
                  <Icon
                    Icon={icon}
                    width={`${iconWidth ?? 18}px`}
                    stroke="inherit"
                  />
                </label>
              )}
              <StyledCheckboxLabel
                htmlFor={field}
                dangerouslySetInnerHTML={{ __html: label ?? '' }}
                $disabled={disabled}
              ></StyledCheckboxLabel>
            </div>
            <StyledCheckbox
              type="checkbox"
              id={field}
              disabled={disabled}
              checked={checked}
              onChange={() => setChecked((prev) => !prev)}
              onClick={() => {
                if (onClick) {
                  onClick()
                }
              }}
              $error={error}
            />
          </>
        )}
      </StyledCheckboxWrapper>
      {error && errorMessage && (
        <StyledErrorLabel $reverse={reverse} $icon={!!icon}>
          {errorMessage}
        </StyledErrorLabel>
      )}
    </div>
  )
}

type CheckboxProps = {
  field: string
  label?: React.ReactNode
  defaultValue?: boolean
  disabled?: boolean
  error?: boolean
  errorMessage?: string
  reverse?: boolean
  onChange: (checked: boolean) => void
  onClick?: () => void
  triggerValidation?: boolean
  validate?: () => void
  icon?: React.FunctionComponent
  iconWidth?: number
}
