import { useContext, useMemo } from 'react'
import { AtomButton } from '../../../atoms/AtomButton'
import { SelectField } from '../../../commons/FormComponents/SelectField'
import { Colors, StyledH4 } from '../../../commons/Theme'
import {
  capitalizeFirstLetterWords,
  prepareStringForDataLayer,
} from '../../../commons/utils'
import { Icon } from '../../Icons/Icon'
import { FlexColumn, StyledFornitura } from '../FormDelegaRiscossione.style'
import { ErediContext } from '../context/ErediContext'
import { TrashIcon } from 'components/Icons/TrashIcon'
import { PlusIcon } from 'components/Icons/PlusIcon'

export const FormRinunciatari = () => {
  const { state, dispatch } = useContext(ErediContext)

  const rinunciatariOptions = useMemo(
    () =>
      state.eredi.map((erede) => ({
        label: capitalizeFirstLetterWords(`${erede.cognome} ${erede.nome}`),
        value: prepareStringForDataLayer(`${erede.cognome} ${erede.nome}`),
      })),
    [state.eredi]
  )

  return (
    <FlexColumn $gap="40px">
      <StyledH4 $weight="500">Rinunciano all'eredità:</StyledH4>
      <FlexColumn $gap="24px">
        {state.rinunciatari.map((rinunciatario, index) => (
          <StyledFornitura key={index}>
            <SelectField
              label="Cognome e Nome"
              placeholder="Seleziona un'opzione"
              options={rinunciatariOptions.filter(
                (option) =>
                  !state.rinunciatari.some(
                    (rinunciatario) => rinunciatario?.value === option.value
                  )
              )}
              disabled={state.eredi.length <= 1}
              value={rinunciatario}
              onChange={(value) =>
                dispatch({
                  type: 'UPDATE_RINUNCIATARIO',
                  payload: {
                    index: index,
                    value: value || { label: '', value: '' },
                  },
                })
              }
            />
            <Icon
              Icon={TrashIcon}
              width="24px"
              stroke={Colors.azure600}
              hoverStroke={Colors.blue400}
              onClick={() =>
                index === 0
                  ? dispatch({
                      type: 'UPDATE_RINUNCIATARIO',
                      payload: {
                        index: index,
                        value: { label: '', value: '' },
                      },
                    })
                  : dispatch({
                      type: 'DELETE_RINUNCIATARIO',
                      payload: { index: index },
                    })
              }
            />
          </StyledFornitura>
        ))}
        {state.rinunciatari.length < state.eredi.length - 1 && (
          <AtomButton
            label="Aggiungi rinunciatario"
            onClick={() =>
              dispatch({
                type: 'ADD_RINUNCIATARIO',
              })
            }
            styleType="ghost"
            icon={<PlusIcon />}
            iconPosition="right"
          />
        )}
      </FlexColumn>
    </FlexColumn>
  )
}
