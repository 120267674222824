import styled from 'styled-components'

export const StyledFormDichiarazioneSostitutiva = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  p,
  p:last-of-type + * {
    margin: 0;
  }
`
