import { useContext } from 'react'
import { Input } from '../../commons/FormComponents/Input'
import { StyledH2 } from '../../commons/Theme'
import { Avviso } from '../Webform/components/Avviso'
import { StyledFormDichiarazioneSostitutiva } from './FormDichiarazioneSostitutiva.style'
import { FormAssegno } from './components/FormAssegno'
import { FormDecedutoReadOnly } from './components/FormDeceduto'
import {
  ErediSecondariReadOnly,
  FormEredeReadOnly,
} from './components/FormErede'
import { FormFornituraReadOnly } from './components/FormFornitura'
import { FormRinunciatari } from './components/FormRinunciatari'
import { FormTestamento } from './components/FormTestamento'
import { ErediContext } from './context/ErediContext'
import { errorHandler } from './helpers/utils'

export const FormDichiarazioneSostitutiva = () => {
  const { state, dispatch, errors, dispatchError, triggerValidation } =
    useContext(ErediContext)

  return (
    <StyledFormDichiarazioneSostitutiva>
      <StyledH2>
        Dichiarazione sostitutiva dell'atto di notorietà per erede
      </StyledH2>
      <FormEredeReadOnly />
      <p>
        valendosi del disposto di cui agli articoli 21, 38 e 47 del d.p.r.
        28/12/2000 n. 445 (Testo unico delle disposizioni legislative e
        regolamentari in materia di documentazione amministrativa) e consapevole
        delle sanzioni previste dal codice penale e dalle leggi speciali in
        materia per il caso di dichiarazione falsa o mendace e l'uso di atto
        falso, come previsto dall'art. 76 del citato d.p.r., dichiara che:
      </p>
      <FormDecedutoReadOnly />
      <FormFornituraReadOnly />
      <FormTestamento />
      <ErediSecondariReadOnly />
      <FormRinunciatari />
      <FormAssegno />
      <p>
        Ai sensi del d.lgs. 196/2003 (Codice in materia di protezione dei dati
        personali) si prende atto che i dati personali forniti saranno trattati
        dall'ente al quale la presente dichiarazione viene prodotta per le
        finalità connesse alla riscossione del credito per cui la dichiarazione
        stessa viene resa e per gli eventuali successivi adempimenti di
        competenza.
      </p>
      <p>
        In conformità all'Art. 38.3 del D.P.R. 28/12/2000 N. 445 la presente è
        sottoscritta e presentata unitamente a copia fotostatica non autenticata
        di un documento d'identità in corso di validità del sottoscrittore.
      </p>
      <Avviso
        style={{ marginTop: '40px' }}
        title="Abbiamo bisogno della tua email."
        message="Ti invieremo alla mail che indicherai il modulo pdf generato, che dovrai firmare e allegare al passaggio successivo."
      />
      <Input
        id={`form-email`}
        label="Email*"
        value={state.email}
        onChange={(value) =>
          dispatch({
            type: 'UPDATE_EMAIL_VALUE',
            payload: value,
          })
        }
        error={errors[`form-email`] || ''}
        isValid={errors[`form-email`] === ''}
        onFocus={() => {
          dispatchError({
            type: 'UPDATE_ERROR_VALUE',
            payload: {
              [`form-email`]: '',
            },
          })
        }}
        validate={() =>
          dispatchError({
            type: 'UPDATE_ERROR_VALUE',
            payload: {
              [`form-email`]: errorHandler('email', state.email),
            },
          })
        }
        validateOnBlur
        triggerValidation={triggerValidation}
      />
    </StyledFormDichiarazioneSostitutiva>
  )
}
