import { useEffect, useRef, useState } from 'react'
import { Input, InputFieldProps } from '../../../commons/FormComponents/Input'
import {
  StyledDropdownItem,
  StyledDropdownModal,
} from '../../../commons/FormComponents/SelectField.style'
import { getGLStreetSuggestion, getGLTownSuggestion } from '../helpers/utils'

const GeolabTownDropdown = ({
  open,
  options,
  onSelect,
}: GeolabDropdownTownProps) => {
  return (
    <StyledDropdownModal $open={open}>
      {options.map((opt, i) => (
        <StyledDropdownItem
          key={i}
          onClick={() => onSelect(opt)}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onSelect(opt)
            }
          }}
        >
          {opt.Des}
        </StyledDropdownItem>
      ))}
    </StyledDropdownModal>
  )
}

const GeolabStreetDropdown = ({
  open,
  options,
  onSelect,
}: GeolabDropdownStreetProps) => {
  return (
    <StyledDropdownModal $open={open}>
      {options.map((opt, i) => (
        <StyledDropdownItem
          key={i}
          onClick={() => onSelect(opt)}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onSelect(opt)
            }
          }}
        >
          {opt.Dug} {opt.Street}
        </StyledDropdownItem>
      ))}
    </StyledDropdownModal>
  )
}

export const InputGeolabStreet = (
  props: Omit<InputFieldProps, 'onSelect'> & {
    onSelect: (value: GeolabStreetSuggestion) => void
    townKey: string
  }
) => {
  const [open, setOpen] = useState<boolean>(false)
  const [streetSuggestions, setStreetSuggestions] = useState<
    GeolabStreetSuggestion[]
  >([])
  const { value, onSelect, onBlur, townKey, ...rest } = props

  const suggestionRef = useRef('')

  useEffect(() => {
    if (value.length > 2 && value !== suggestionRef.current) {
      townKey &&
        getGLStreetSuggestion(value, townKey).then(
          (res: GeolabStreetSuggestion[]) => {
            setOpen(res.length > 0)
            setStreetSuggestions(res)
          }
        )
    } else {
      setOpen(false)
    }
  }, [value])

  return (
    <div style={{ position: 'relative' }}>
      <Input value={value} onBlur={onBlur} {...rest} />
      <GeolabStreetDropdown
        open={open}
        options={streetSuggestions}
        onSelect={(option) => {
          setOpen(false)
          suggestionRef.current = `${option.Dug} ${option.Street}`
          onSelect(option)
        }}
      />
    </div>
  )
}

export const InputGeolabTown = (
  props: Omit<InputFieldProps, 'onSelect'> & {
    onSelect: (value: GeolabTownSuggestion) => void
  }
) => {
  const [open, setOpen] = useState<boolean>(false)
  const [townSuggestions, setTownSuggestions] = useState<
    GeolabTownSuggestion[]
  >([])

  const { value, onSelect, onBlur, ...rest } = props

  const suggestionRef = useRef('')

  useEffect(() => {
    if (value.length > 2 && value !== suggestionRef.current) {
      getGLTownSuggestion(value).then(
        (res: {
          VillageList: GeolabTownSuggestion[]
          TownList: GeolabTownSuggestion[]
        }) => {
          const suggestions = [...res.TownList, ...res.VillageList]
          setOpen(suggestions.length > 0)
          setTownSuggestions(suggestions)
        }
      )
    } else {
      setOpen(false)
    }
  }, [value])

  return (
    <div style={{ position: 'relative' }}>
      <Input value={value} onBlur={onBlur} {...rest} />
      <GeolabTownDropdown
        open={open}
        options={townSuggestions}
        onSelect={(option) => {
          setOpen(false)
          suggestionRef.current = option.Des
          onSelect(option)
        }}
      />
    </div>
  )
}

type GeolabTownSuggestion = {
  Zip: string
  DesProv: string
  Des: string
  TownDes: string
  Istat: string
  TownKey?: number
  TownDesShort: string
  Catasto: string
  RegionIstat: string
  Key: number
  DesShort: string
  Prov: string
}

type GeolabStreetSuggestion = {
  Zip: string
  Village: string
  Dug: string
  X: string
  Street: string
  Y: string
  Lang: number
  Range: string
  VillageKey: number
  Key: number
  StreetShort: string
}

type GeolabDropdownTownProps = {
  open: boolean
  options: GeolabTownSuggestion[]
  onSelect: (value: GeolabTownSuggestion) => void
}

type GeolabDropdownStreetProps = {
  open: boolean
  options: GeolabStreetSuggestion[]
  onSelect: (value: GeolabStreetSuggestion) => void
}
