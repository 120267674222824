import { FormErediMain } from './FormErediMain'
import { ErediContextProvider } from './context/ErediContext'

export const FormEredi = () => {
  return (
    <ErediContextProvider>
      <FormErediMain />
    </ErediContextProvider>
  )
}
