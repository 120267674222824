import { Font, Text, View } from '@react-pdf/renderer'
import styles from './PDF.styled'
import { DeclarationData, DeclarationRenouncersData } from './PDF.types'

const hyphenationCallback = (word: string) => {
  return [word]
}

const filler = (size: number) => {
  let res = ''
  for (let i = 0; i < size; i++) {
    res += '_'
  }
  return res
}

Font.registerHyphenationCallback(hyphenationCallback)
const Sostitutiva = ({ data }: { data: DeclarationData }) => {
  return (
    <View>
      <View>
        <Text style={[styles.title, { fontSize: 14.2, marginBottom: 2.2 }]}>
          DICHIARAZIONE SOSTITUTIVA DELL’ATTO DI NOTORIETÀ
        </Text>
      </View>
      {data && (
        <View>
          <View style={[{ lineHeight: 1.25, marginBottom: 5 }]}>
            <Text>
              Il/La sottoscritto/a{' '}
              <Text style={styles.dynamic}>
                {data.user.name} {data.user.surname}
              </Text>
            </Text>
            <Text>
              nato/a a{' '}
              <Text style={styles.dynamic}>{data.user.birthplace}</Text> il{' '}
              <Text style={styles.dynamic}>{data.user.birthdate}</Text>{' '}
              residente a{' '}
              <Text style={styles.dynamic}>{data.user.birthplace}</Text>
            </Text>
            <Text>
              in via/piazza{' '}
              <Text style={styles.dynamic}>{data.user.address}</Text> n.{' '}
              <Text style={styles.dynamic}>{data.user.number}</Text>
              <Text>
                , valendosi del disposto di cui agli articoli 21, 38 e 47 del{' '}
              </Text>
              <Text style={styles.underline}>d.p.r. 28/12/2000 n. 445</Text> (
              <Text style={styles.italic}>
                Testo unico delle disposizioni legislative e regolamentari in
                materia di documentazione amministrativa
              </Text>
              ) e consapevole delle sanzioni previste dal codice penale e dalle
              leggi speciali in materia per il caso di dichiarazione falsa o
              mendace e l’uso di atto falso, come previsto dall’art. 76 del
              citato d.p.r., dichiara:
            </Text>
          </View>
          <View>
            <View style={styles.listItem}>
              <View style={styles.listDot}>
                <Text>•</Text>
              </View>
              <View style={styles.listContent}>
                <Text>
                  <Text>
                    che il/la Sig./ra{' '}
                    <Text style={styles.dynamic}>
                      {data.death.name} {data.death.surname}
                    </Text>{' '}
                    nato/a a{' '}
                    <Text style={styles.dynamic}>{data.death.birthplace}</Text>
                  </Text>
                </Text>
                <Text>
                  <Text>
                    il{' '}
                    <Text style={styles.dynamic}>{data.death.birthdate} </Text>
                  </Text>
                  e residente alla data del decesso nel Comune di{' '}
                  <Text style={styles.dynamic}>{data.death.city}</Text>
                </Text>
                <Text>
                  in via/piazza{' '}
                  <Text style={styles.dynamic}>{data.death.address}</Text> n.{' '}
                  <Text style={styles.dynamic}>{data.death.number}</Text>{' '}
                  intestatario del contratto di fornitura n.{' '}
                  <Text style={styles.dynamic}>{data.death.contract}</Text>
                </Text>
                <Text>
                  è deceduto/a il{' '}
                  <Text style={styles.dynamic}>{data.death.deathdate}</Text>:
                </Text>
                <View
                  style={[
                    styles.checkboxWrapper,
                    { marginTop: 2, marginBottom: 8 },
                  ]}
                >
                  <View
                    style={[
                      styles.checkbox,
                      !data.death.will ? styles.checkboxChecked : {},
                      { top: 0 },
                    ]}
                  ></View>
                  <Text>
                    senza lasciare testamento, né disposizioni di sue ultime
                    volontà, <Text style={styles.italic}>oppure</Text>
                  </Text>
                </View>
                <View style={[styles.checkboxWrapper, { marginTop: -4 }]}>
                  <View
                    style={[
                      styles.checkbox,
                      data.death.will ? styles.checkboxChecked : {},
                      { top: 0 },
                    ]}
                  ></View>
                  <Text>
                    lasciando precise disposizioni testamentarie con testamento
                    che si allega in copia
                  </Text>
                </View>
              </View>
            </View>
            <View style={[styles.listItem, { marginTop: 10 }]}>
              <View style={styles.listDot}>
                <Text>•</Text>
              </View>
              <View style={[styles.listContent, styles.flex]}>
                <Text>che pertanto gli eredi</Text>
                <View
                  style={[
                    styles.checkbox,
                    data.death.legitimate ? styles.checkboxChecked : {},
                    { marginLeft: 3, marginRight: 3, top: -1 },
                  ]}
                ></View>
                <Text>legittimi</Text>
                <View
                  style={[
                    styles.checkbox,
                    data.death.testamentary ? styles.checkboxChecked : {},
                    { marginLeft: 3, marginRight: 3, top: -1 },
                  ]}
                ></View>
                <Text>testamentari sono:</Text>
              </View>
            </View>
          </View>
          <View style={[styles.heirs, { marginTop: -8 }]}>
            <View style={styles.heirsRow}>
              <View style={styles.heirsCell}>
                <Text>n.</Text>
              </View>
              <View style={[styles.heirsCell, styles.heirsCellData]}>
                <Text>
                  cognome, nome, luogo e data di nascita, nazionalità,
                  residenza, legame di parentela con il de cuius
                </Text>
              </View>
            </View>
            {Array.from(Array(5)).map((value: undefined, index: number) => {
              const heir = data.heirs[index]
              return (
                <View style={styles.heirsRow} key={index}>
                  <View style={styles.heirsCell}>
                    <Text>{index + 1}</Text>
                  </View>
                  <View style={[styles.heirsCell, styles.heirsCellData]}>
                    {heir && (
                      <Text>
                        {heir.surname}, {heir.name}, {heir.birthplace},{' '}
                        {heir.birthdate}, {heir.nationality}, {heir.city},{' '}
                        {heir.address}, {heir.number}, {heir.kinship}
                      </Text>
                    )}
                  </View>
                </View>
              )
            })}
          </View>
          <View style={[{ marginTop: 8 }]}>
            <View style={[styles.listItem, { marginBottom: -14 }]}>
              <View style={styles.listDot}>
                <Text>•</Text>
              </View>
              <View style={styles.listContent}>
                <Text>
                  che tutti gli eredi sopraccitati sono maggiori di età ed hanno
                  la piena capacità di agire
                </Text>
              </View>
            </View>
            <View style={[styles.listItem, { marginBottom: -14 }]}>
              <View style={styles.listDot}>
                <Text>•</Text>
              </View>
              <View style={styles.listContent}>
                <Text>
                  che nessuno degli eredi/aventi diritto all’eredità è incorso
                  in cause di indegnità a succedere
                </Text>
              </View>
            </View>
            <View style={[styles.listItem, { marginTop: 6 }]}>
              <View style={styles.listDot}>
                <Text>•</Text>
              </View>
              <View style={styles.listContent}>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View
                    style={[
                      styles.checkbox,
                      data.renouncers.length ? styles.checkboxChecked : {},
                      { top: 2 },
                    ]}
                  ></View>
                  <Text>che il Sig./ri</Text>
                  <Text> </Text>
                  {data.renouncers.map(
                    (renouncers: DeclarationRenouncersData, index: number) => (
                      <Text key={index}>
                        {data.renouncers.length > 1 &&
                          index > 0 &&
                          index < data.renouncers.length &&
                          ', '}
                        {renouncers.name}
                      </Text>
                    )
                  )}
                  <Text> </Text>
                  {!data.renouncers.length && <Text>{filler(80)} </Text>}
                </View>
                <Text>rinuncia/no all’eredità</Text>
              </View>
            </View>
            <View style={styles.listItem}>
              <View style={styles.listDot}>
                <Text>•</Text>
              </View>
              <View style={styles.listContent}>
                <Text>
                  che non esistono altre persone, oltre a quelle indicate
                  nell’atto, che possano vantare quote di legittima o riserva o
                  altre ragioni sull’eredità, a qualunque titolo
                </Text>
              </View>
            </View>
            <View style={styles.listItem}>
              <View style={styles.listDot}>
                <Text>•</Text>
              </View>
              <View style={styles.listContent}>
                <Text>
                  di essere delegato dai soggetti di cui all’Allegato A alla
                  riscossione dei crediti relativi al contratto di fornitura
                  sopra richiamato, manlevando A2A Energia S.p.A. da ogni
                  responsabilità derivante dalla riscossione
                </Text>
              </View>
            </View>
            <View style={styles.listItem}>
              <View style={styles.listDot}>
                <Text>•</Text>
              </View>
              <View style={styles.listContent}>
                <Text>
                  che per la riscossione dei predetti contributi viene indicata
                  la seguente modalità:
                </Text>
              </View>
            </View>
            <View style={[styles.listItem, { marginTop: -4 }]}>
              <View style={styles.paddingLeft}>
                <View style={styles.checkboxWrapper}>
                  <View>
                    <View style={[styles.flex, { lineHeight: 1.2 }]}>
                      <View
                        style={[
                          styles.checkbox,
                          data.bank.checked ? styles.checkboxChecked : {},
                          { top: -0.5 },
                        ]}
                      ></View>
                      <Text>
                        Bonifico bancario sul c/c intestato al delegato:{' '}
                        <Text>
                          Beneficiario{' '}
                          {data.bank.checked ? (
                            <Text style={styles.dynamic}>
                              {data.bank.beneficiario}{' '}
                            </Text>
                          ) : (
                            <Text>{filler(30)} </Text>
                          )}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.listPadding,
                        { marginTop: 2, marginBottom: -4 },
                      ]}
                    >
                      <Text>
                        <Text style={styles.bold}>CODICE IBAN:</Text>{' '}
                        {data.bank.checked ? (
                          <Text style={styles.dynamic}>{data.bank.iban}</Text>
                        ) : (
                          <Text>{filler(36)} </Text>
                        )}
                        {data.bank.swift && (
                          <Text>
                            {data.bank.swift ? (
                              <Text>
                                {' '}
                                SWIFT/BIC{' '}
                                <Text style={styles.dynamic}>
                                  {data.bank.swift}
                                </Text>
                              </Text>
                            ) : (
                              <Text>{filler(30)}</Text>
                            )}
                          </Text>
                        )}
                      </Text>
                    </View>
                    <View style={styles.listPadding}>
                      <Text>
                        Banca/Posta{' '}
                        {data.bank.checked ? (
                          <Text style={styles.dynamic}>{data.bank.name} </Text>
                        ) : (
                          <Text>{filler(30)} </Text>
                        )}
                        Agenzia/Filiale di{' '}
                        {data.bank.checked ? (
                          <Text style={styles.dynamic}>{data.bank.agency}</Text>
                        ) : (
                          <Text>{filler(30)}</Text>
                        )}
                      </Text>
                    </View>
                  </View>
                  <Text></Text>
                </View>
                <View
                  style={[
                    styles.checkboxWrapper,
                    { lineHeight: 1.2, marginBottom: 6 },
                  ]}
                >
                  <View>
                    <View style={[styles.flex]}>
                      <View
                        style={[
                          styles.checkbox,
                          data.delegatedCheck.checked
                            ? styles.checkboxChecked
                            : {},
                          { top: -0.5 },
                        ]}
                      ></View>
                      <Text>
                        Assegno di traenza intestato al delegato da recapitare
                        al seguente indirizzo:{' '}
                      </Text>
                    </View>
                    <View style={[styles.listPadding, { marginTop: 1.5 }]}>
                      <Text>
                        presso{' '}
                        {data.delegatedCheck.checked ? (
                          <Text style={styles.dynamic}>
                            {data.delegatedCheck.beneficiario}
                          </Text>
                        ) : (
                          <Text>{filler(4)} </Text>
                        )}{' '}
                        via/piazza{' '}
                        {data.delegatedCheck.checked ? (
                          <Text style={styles.dynamic}>
                            {data.delegatedCheck.address}{' '}
                          </Text>
                        ) : (
                          <Text>{filler(30)} </Text>
                        )}
                        n.{' '}
                        {data.delegatedCheck.checked ? (
                          <Text style={styles.dynamic}>
                            {data.delegatedCheck.number}
                          </Text>
                        ) : (
                          <Text>{filler(4)} </Text>
                        )}
                      </Text>
                      <View style={{ marginTop: 1.5 }}>
                        <Text>
                          località{' '}
                          {data.delegatedCheck.checked ? (
                            <Text style={styles.dynamic}>
                              {data.delegatedCheck.city}{' '}
                            </Text>
                          ) : (
                            <Text>{filler(30)} </Text>
                          )}
                          provincia{' '}
                          {data.delegatedCheck.checked ? (
                            <Text style={styles.dynamic}>
                              {data.delegatedCheck.country}{' '}
                            </Text>
                          ) : (
                            <Text>{filler(3)} </Text>
                          )}
                          CAP{' '}
                          {data.delegatedCheck.checked ? (
                            <Text style={styles.dynamic}>
                              {data.delegatedCheck.cap}
                            </Text>
                          ) : (
                            <Text>{filler(5)} </Text>
                          )}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View style={[{ marginBottom: 15 }]}>
              <Text style={styles.paragraph}>
                Ai sensi del d.lgs. 196/2003 (Codice in materia di protezione
                dei dati personali) si prende atto che i dati personali forniti
                saranno trattati dall’ente al quale la presente dichiarazione
                viene prodotta per le finalità connesse alla riscossione del
                credito per cui la dichiarazione stessa viene resa e per gli
                eventuali successivi adempimenti di competenza.
              </Text>
            </View>
            <View wrap={false}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: 6,
                  marginBottom: 11,
                  paddingHorizontal: 10,
                }}
              >
                <View
                  style={[
                    styles.flex,
                    {
                      width: '55%',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    },
                  ]}
                >
                  <Text>luogo e data della sottoscrizione</Text>
                  <Text>{filler(25)} |__|__|/|__|__|/|__|__|__|__|</Text>
                </View>
                <View
                  style={[
                    styles.flex,
                    {
                      width: '45%',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    },
                  ]}
                >
                  <Text>firma del dichiarante</Text>
                  <Text>{filler(40)}</Text>
                </View>
              </View>
            </View>
            <View>
              <Text style={[{ fontSize: 8, marginBottom: 8 }]}>
                Esente dall’imposta di bollo ai sensi dell’art. 37.1 d.p.r.
                445/2000
              </Text>
            </View>
          </View>
        </View>
      )}
      <View wrap={false}>
        <Text style={styles.paragraph}>
          In conformità all’Art. 38.3 del D.P.R. 28/12/2000 N. 445 la presente è
          sottoscritta e presentata unitamente a copia fotostatica non
          autenticata di un documento d’identità in corso di validità del
          sottoscrittore.
        </Text>
        <View style={styles.footer}>
          <Text>Modulo predisposto da A2A Energia S.p.A.</Text>
        </View>
      </View>
    </View>
  )
}

export default Sostitutiva
