import { createContext, FC, PropsWithChildren } from 'react'
import { SiteMetadataModel } from '../commons/Common.types'

interface A2ADataContextModel {
  data?: {
    site: {
      siteMetadata: SiteMetadataModel
    }
  }
}

const A2ADataContext = createContext<A2ADataContextModel>({})

const A2ADataContextProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <A2ADataContext.Provider value={{}}>{children}</A2ADataContext.Provider>
  )
}

export default A2ADataContext

export { A2ADataContextProvider }
