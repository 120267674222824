import { useContext, useEffect, useState } from 'react'
import { AtomButton } from '../../atoms/AtomButton'
import { LoaderModal } from '../Modale/LoaderModal/LoaderModal'
import { FormDelegaRiscossione } from './FormDelegaRiscossione'
import { FlexColumn } from './FormDelegaRiscossione.style'
import { FormDichiarazioneSostitutiva } from './FormDichiarazioneSostitutiva'
import { FormCaricaFile } from './components/FormCaricaFile'
import { FormTYP } from './components/FormTYP'
import { ErediContext, ErediContextState } from './context/ErediContext'
import { postErediData, validateToken } from './helpers/utils'
import { consoleLog, createFileEntity, getBase64 } from '../../commons/utils'
import PDF from './components/PDF/PDF'
//@ts-expect-error Import js
import { usePDF } from '@react-pdf/renderer/lib/react-pdf.browser.es.js'
import { useRecaptcha } from '../../hooks/useRecaptcha'
import { SiteSpacings } from 'commons/Theme'

export type TYPType = 'ok' | 'ko' | null

const fakeDownload = (url: string) => {
  const link = document.createElement('a')
  link.setAttribute('href', url)
  link.setAttribute('download', 'delega_riscossione_eredi.pdf')
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export const FormErediMain = () => {
  const [showTYP, setShowTYP] = useState<TYPType>(null)
  const [tokenData, setTokenData] = useState<ErediContextState | null>(null)
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [fullyValidatedOnce, setFullyValidatedOnce] = useState<boolean>(false)
  const { verifyRecaptcha } = useRecaptcha()

  const { state, errors, triggerValidation, setTriggerValidation } =
    useContext(ErediContext)

  const [pdfInstance, pdfUpdate] = usePDF({ document: <PDF data={state} /> })

  function handleOnSubmit() {
    consoleLog(
      '%c State',
      'background: dodgerblue; color: white; padding: 6px 8px 4px 2px; border-radius: 999px',
      state
    )

    setShowLoader(true)
    verifyRecaptcha({
      onSuccess: async () => {
        const RAW = (await getBase64(pdfInstance.blob as File).then(
          (res) => res
        )) as string

        const fileId = await createFileEntity({
          base64: RAW,
          fileName: `delega_riscossione_eredi.pdf`,
          mimeType: 'application/pdf',
          directoryPath: 'private://moduli-eredi/documents',
        })
        if (fileId) {
          postErediData({ ...state, allegato: String(fileId) })
            .then((res) => {
              if (res.status === true) {
                fakeDownload(pdfInstance.url as string)
                setShowTYP('ok')
              } else {
                setShowTYP('ko')
              }
            })
            .catch(() => setShowTYP('ko'))
            .finally(() => setShowLoader(false))
        } else {
          setShowTYP('ko')
          setShowLoader(false)
        }
      },
      onError: () => {
        console.warn('Error with Google Recaptcha')
        setShowTYP(null)
        setShowLoader(false)
      },
    })
  }

  useEffect(() => {
    pdfUpdate()
  }, [state])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const code = urlParams.get('code')
    if (code) {
      setShowLoader(true)
      validateToken(code)
        .then((res) => {
          if (res.status) {
            setTokenData(res.data)
          } else {
            setShowTYP('ko')
            setTokenData(null)
          }
        })
        .catch(() => {
          setShowTYP('ko')
          setTokenData(null)
        })
        .finally(() => {
          setShowLoader(false)
          setTriggerValidation(false)
        })
    }
  }, [])

  useEffect(() => {
    // consoleLog(
    //   '%c Errors',
    //   'background: tomato; color: white; padding: 6px 8px 4px 2px; border-radius: 999px',
    //   errors
    // )

    if (triggerValidation) {
      const errorKeyValue = Object.entries(errors).find(([, value]) => !!value)
      if (!errorKeyValue) {
        fullyValidatedOnce && !showLoader && handleOnSubmit()
      } else {
        const [key] = errorKeyValue
        const errorInputNode = document.getElementById(key)
        if (errorInputNode) {
          const topOffset = errorInputNode.getBoundingClientRect().top
          window.scrollBy({
            behavior: 'smooth',
            top: topOffset - 100,
          })
          setTriggerValidation(false)
        }
      }

      setFullyValidatedOnce(true)
    }
  }, [triggerValidation, errors])

  if (showTYP)
    return (
      <FormTYP
        type={showTYP}
        onBack={() => setShowTYP(null)}
        okTitle="Moduli compilati con successo!"
        okBody={
          <>
            Controlla la tua <strong>mail</strong>, ti abbiamo appena mandato un{' '}
            <strong>link</strong>.
          </>
        }
        koTitle="Qualcosa è andato storto!"
        koBody={<>C'è stato un errore nell'invio del modulo.</>}
      />
    )

  return (
    <FlexColumn $gap={`${SiteSpacings.Spacing10.lg}px`}>
      {tokenData ? (
        <FormCaricaFile
          data={tokenData}
          showLoader={() => setShowLoader(true)}
          hideLoader={() => setShowLoader(false)}
        />
      ) : (
        <>
          <FormDelegaRiscossione />
          <FlexColumn $gap="40px">
            <FormDichiarazioneSostitutiva />
            <AtomButton
              label="Invia e Scarica PDF"
              disabled={!!pdfInstance.loading}
              onClick={() => setTriggerValidation(true)}
            />
          </FlexColumn>
        </>
      )}
      <LoaderModal showModal={showLoader} fullScreen={false} />
    </FlexColumn>
  )
}
