import { useContext, useState } from 'react'
import { Checkbox } from '../../../commons/FormComponents/Checkbox'
import { Input } from '../../../commons/FormComponents/Input'
import { FlexColumn, StyledFormGrid } from '../FormDelegaRiscossione.style'
import { ErediContext } from '../context/ErediContext'
import { errorHandler } from '../helpers/utils'
import { InputRadio } from 'commons/FormComponents/InputRadio'

export const FormAssegno = () => {
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const { state, dispatch, errors, dispatchError, triggerValidation } =
    useContext(ErediContext)

  return (
    <FlexColumn $gap="24px">
      <div>
        <p>Il sottoscritto/a dichiara inoltre che:</p>
        <br />
        <ul style={{ listStylePosition: 'outside', marginLeft: '20px' }}>
          <li>
            che tutti gli eredi sopraccitati sono maggiori di età ed hanno la
            piena capacità di agire
          </li>
          <br />
          <li>
            che nessuno degli eredi/aventi diritto all'eredità è incorso in
            cause di indegnità a succedere
          </li>
          <br />
          <li>
            che non esistono altre persone, oltre a quelle indicate nell'atto,
            che possano vantare quote di legittima o riserva o altre ragioni
            sull'eredità, a qualunque titolo
          </li>
          <br />
          <li>
            di essere delegato dai soggetti di cui all'Allegato A alla
            riscossione dei crediti relativi al contratto di fornitura sopra
            richiamato, manlevando A2A Energia S.p.A. da ogni responsabilità
            derivante dalla riscossione
          </li>
          <br />
          <li>
            che per la riscossione dei predetti contributi viene indicata la
            seguente modalità:
          </li>
        </ul>
      </div>
      <InputRadio
        id="pagamento-bonifico"
        value={state.pagamento?.type ?? 'bonifico'}
        name="pagamento"
        label="Bonifico bancario sul c/c intestato al delegato"
        checked={state.pagamento?.type === 'bonifico'}
        onChange={() => {
          dispatch({
            type: 'UPDATE_PAGAMENTO_TYPE',
            payload: 'bonifico',
          })
          dispatchError({ type: 'CLEAN_ERRORS_WITH_KEY', payload: 'assegno-' })
        }}
      />
      {state.pagamento?.type === 'bonifico' && (
        <StyledFormGrid $gap="24px">
          <Input
            id="bonifico-beneficiario"
            label="Titolare del conto*"
            value={
              state.delegatoIsSottoscritto
                ? `${state.eredi[0]?.nome} ${state.eredi[0]?.cognome}`
                : `${state.delegato.nome} ${state.delegato.cognome}`
            }
            onChange={() => {}}
            readOnly
          />
          <FlexColumn $gap="8px">
            <Input
              id="bonifico-iban"
              label="IBAN*"
              value={state.pagamento.value.iban}
              onChange={(value) => {
                setIsDirty(true)
                dispatch({
                  type: 'UPDATE_PAGAMENTO_VALUE',
                  payload: { iban: value },
                })
              }}
              onFocus={() => {
                dispatchError({
                  type: 'UPDATE_ERROR_VALUE',
                  payload: {
                    [`bonifico-iban`]: '',
                  },
                })
              }}
              error={errors[`bonifico-iban`] || ''}
              isValid={errors[`bonifico-iban`] === ''}
              validate={() =>
                dispatchError({
                  type: 'UPDATE_ERROR_VALUE',
                  payload: {
                    //@ts-expect-error Pagamento value must be improved
                    [`bonifico-iban`]: state.pagamento.value.isIBANStraniero
                      ? errorHandler(
                          'obbligatorio',
                          //@ts-expect-error Pagamento value must be improved
                          state.pagamento.value.iban || ''
                        )
                      : errorHandler(
                          'ibanItaliano',
                          //@ts-expect-error Pagamento value must be improved
                          state.pagamento.value.iban || ''
                        ),
                  },
                })
              }
              validateOnBlur
              triggerValidation={triggerValidation}
            />
            <Checkbox
              field="bonifico-straniero-iban"
              label="IBAN Straniero"
              onChange={(value) => {
                dispatch({
                  type: 'UPDATE_PAGAMENTO_VALUE',
                  payload: { isIBANStraniero: value },
                })
                // validate iban again
                if (value === true) {
                  isDirty &&
                    dispatchError({
                      type: 'UPDATE_ERROR_VALUE',
                      payload: {
                        [`bonifico-iban`]: errorHandler(
                          'obbligatorio',
                          //@ts-expect-error Pagamento value must be improved
                          state.pagamento.value.iban || ''
                        ),
                      },
                    })
                } else {
                  isDirty &&
                    dispatchError({
                      type: 'UPDATE_ERROR_VALUE',
                      payload: {
                        [`bonifico-iban`]: value
                          ? errorHandler(
                              'obbligatorio',
                              //@ts-expect-error Pagamento value must be improved
                              state.pagamento.value.iban || ''
                            )
                          : errorHandler(
                              'ibanItaliano',
                              //@ts-expect-error Pagamento value must be improved
                              state.pagamento.value.iban || ''
                            ),
                      },
                    })
                  dispatch({
                    type: 'UPDATE_PAGAMENTO_VALUE',
                    payload: { swift: '' },
                  })
                  dispatchError({
                    type: 'CLEAN_ERRORS_WITH_KEY',
                    payload: 'bonifico-straniero-',
                  })
                }
              }}
            />
          </FlexColumn>
          {state.pagamento.type === 'bonifico' &&
            state.pagamento.value.isIBANStraniero === true && (
              <>
                <div />
                <Input
                  id="bonifico-straniero-swift"
                  label="SWIFT/BIC*"
                  value={state.pagamento.value.swift}
                  onChange={(value) =>
                    dispatch({
                      type: 'UPDATE_PAGAMENTO_VALUE',
                      payload: { swift: value },
                    })
                  }
                  onFocus={() => {
                    dispatchError({
                      type: 'UPDATE_ERROR_VALUE',
                      payload: {
                        [`bonifico-straniero-swift`]: '',
                      },
                    })
                  }}
                  error={errors[`bonifico-straniero-swift`] || ''}
                  isValid={errors[`bonifico-straniero-swift`] === ''}
                  validate={() =>
                    dispatchError({
                      type: 'UPDATE_ERROR_VALUE',
                      payload: {
                        [`bonifico-straniero-swift`]: errorHandler(
                          'obbligatorio',
                          //@ts-expect-error Pagamento value must be improved
                          state.pagamento.value.swift || ''
                        ),
                      },
                    })
                  }
                  validateOnBlur
                  triggerValidation={triggerValidation}
                />
              </>
            )}
          <Input
            id="bonifico-banca"
            label="Banca/Posta"
            value={state.pagamento.value.banca}
            onChange={(value) =>
              dispatch({
                type: 'UPDATE_PAGAMENTO_VALUE',
                payload: { banca: value },
              })
            }
          />
          <Input
            id="bonifico-agenzia"
            label="Agenzia/Filiale di"
            value={state.pagamento.value.filiale}
            onChange={(value) =>
              dispatch({
                type: 'UPDATE_PAGAMENTO_VALUE',
                payload: { filiale: value },
              })
            }
          />
        </StyledFormGrid>
      )}
      <InputRadio
        id="pagamento-assegno"
        value={state.pagamento?.type ?? 'assegno'}
        name="pagamento"
        label="Assegno di traenza intestato al delegato da recapitare al seguente indirizzo"
        checked={state.pagamento?.type === 'assegno'}
        onChange={() => {
          dispatch({
            type: 'UPDATE_PAGAMENTO_TYPE',
            payload: 'assegno',
          })
          dispatchError({ type: 'CLEAN_ERRORS_WITH_KEY', payload: 'bonifico-' })
        }}
        tooltip={
          <>
            Attenzione, per l'invio dell'assegno è possibile indicare
            esclusivamente un indirizzo italiano
          </>
        }
      />
      {state.pagamento?.type === 'assegno' && (
        <StyledFormGrid $gap={'24px'}>
          <Input
            id="assegno-presso"
            label="Delegato*"
            value={
              state.delegatoIsSottoscritto
                ? `${state.eredi[0]?.nome} ${state.eredi[0]?.cognome}`
                : `${state.delegato.nome} ${state.delegato.cognome}`
            }
            onChange={() => {}}
            readOnly
          />
          <Input
            id="assegno-localita"
            label="Città*"
            value={
              (state.delegatoIsSottoscritto
                ? state.eredi[0]?.comuneResidenza
                : state.delegato.comuneResidenza) || ''
            }
            onChange={() => {}}
            readOnly
          />
          <Input
            id="assegno-provincia"
            label="Provincia*"
            value={
              (state.delegatoIsSottoscritto
                ? state.eredi[0]?.provinciaResidenza
                : state.delegato.provinciaResidenza) || ''
            }
            onChange={() => {}}
            readOnly
          />
          <Input
            id="assegno-cap"
            label="CAP*"
            value={
              (state.delegatoIsSottoscritto
                ? state.eredi[0]?.CAP
                : state.delegato.CAP) ?? ''
            }
            onChange={() => {}}
            readOnly
          />
          <Input
            id="assegno-via"
            label="Via/Piazza*"
            value={
              (state.delegatoIsSottoscritto
                ? state.eredi[0]?.indirizzoResidenza
                : state.delegato.indirizzoResidenza) || ''
            }
            onChange={() => {}}
            readOnly
          />
          <Input
            id="assegno-civico"
            label="Numero civico*"
            value={
              (state.delegatoIsSottoscritto
                ? state.eredi[0]?.numeroCivico
                : state.delegato.numeroCivico) || ''
            }
            onChange={() => {}}
            readOnly
          />
        </StyledFormGrid>
      )}
    </FlexColumn>
  )
}
