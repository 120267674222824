import styled, { css } from 'styled-components'
import { Colors, BPSizes, Rem } from '../../commons/Theme'
import { BreadcrumbLinkProps } from './breadcrumb.types'
import Link from '../../components/Link/Link'
import { ContainerStyle } from 'commons/Container'

export const StyledBreadcrumbsWrapper = styled.div<{
  $showMobile: boolean
}>`
  display: ${({ $showMobile }) => ($showMobile ? 'flex' : 'none')};
  flex-direction: row;
  padding-top: 16px;
  padding-bottom: 16px;
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  ${ContainerStyle};

  @media (min-width: ${BPSizes.tablet}px) {
    display: flex;
  }
`

export const StyledBreadcrumb = styled.div`
  display: flex;
  flex-direction: row;
  font-size: ${Rem(12)};
`

export const StyledBreadcrumbLink = styled(Link)<BreadcrumbLinkProps>`
  color: ${Colors.grey600};
  text-decoration: none;

  &:hover {
    color: ${Colors.azure600};
    cursor: pointer;
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${Colors.baseFont};
      pointer-events: none;
    `};
`

export const StyledBreadcrumbDivider = styled.div`
  color: ${Colors.grey600};
  padding: 0 2px;
`

export const StyledOverflowGradient = styled.div`
  background: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 0), white);
  position: absolute;
  pointer-events: none;
  right: 0;
  top: 0;
  height: 100%;
  width: 20px;
  z-index: 1;
`
