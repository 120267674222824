import { useContext } from 'react'
import { ErediContext, TestamentoType } from '../context/ErediContext'
import { FlexColumn } from '../FormDelegaRiscossione.style'
import { Checkbox } from '../../../commons/FormComponents/Checkbox'
import { errorHandler } from '../helpers/utils'
import { InputRadio } from 'commons/FormComponents/InputRadio'

export const FormTestamento = () => {
  const { state, dispatch, errors, dispatchError, triggerValidation } =
    useContext(ErediContext)

  return (
    <FlexColumn $gap="40px">
      <FlexColumn $gap="24px">
        <p id="testamento">è deceduto/a</p>
        <InputRadio
          name="deceduto-testamento"
          value="testamento-no"
          label="senza lasciare testamento, né disposizioni di sue ultime volontà"
          onChange={(e) => {
            dispatch({
              type: 'UPDATE_TESTAMENTO_VALUE',
              payload: e.target.value as TestamentoType,
            })
            dispatchError({
              type: 'UPDATE_ERROR_VALUE',
              payload: {
                [`testamento`]: '',
              },
            })
          }}
          error={!!errors[`testamento`]}
          validate={() =>
            dispatchError({
              type: 'UPDATE_ERROR_VALUE',
              payload: {
                [`testamento`]: errorHandler('obbligatorio', state.testamento),
              },
            })
          }
          triggerValidation={triggerValidation}
        />
        <InputRadio
          name="deceduto-testamento"
          value="testamento-si"
          label="lasciando precise disposizioni testamentarie con testamento che si allega in copia"
          onChange={(e) => {
            dispatch({
              type: 'UPDATE_TESTAMENTO_VALUE',
              payload: e.target.value as TestamentoType,
            })
            dispatchError({
              type: 'UPDATE_ERROR_VALUE',
              payload: {
                [`testamento`]: '',
              },
            })
          }}
          error={!!errors[`testamento`]}
        />
      </FlexColumn>
      <FlexColumn $gap="24px">
        <p>Pertanto gli eredi sono*:</p>
        <Checkbox
          field="eredi-testamentari"
          label="Testamentari"
          onChange={(checked) => {
            dispatch({
              type: checked ? 'ADD_TIPO_EREDE' : 'DELETE_TIPO_EREDE',
              payload: 'testamentari',
            })
            checked &&
              dispatchError({
                type: 'UPDATE_ERROR_VALUE',
                payload: {
                  [`eredi-testamentari`]: '',
                },
              })
          }}
          error={!!errors[`eredi-testamentari`]}
          validate={() =>
            dispatchError({
              type: 'UPDATE_ERROR_VALUE',
              payload: {
                [`eredi-testamentari`]: errorHandler(
                  'obbligatorio',
                  state.tipoEredi[0] || ''
                ),
              },
            })
          }
          triggerValidation={triggerValidation}
        />
        <Checkbox
          field="eredi-legittimi"
          label="Legittimi"
          onChange={(checked) => {
            dispatch({
              type: checked ? 'ADD_TIPO_EREDE' : 'DELETE_TIPO_EREDE',
              payload: 'legittimi',
            })
            checked &&
              dispatchError({
                type: 'UPDATE_ERROR_VALUE',
                payload: {
                  [`eredi-testamentari`]: '',
                },
              })
          }}
          error={!!errors[`eredi-testamentari`]}
        />
      </FlexColumn>
    </FlexColumn>
  )
}
