import styled, { css } from 'styled-components'
import { Palette, Rem, SiteSpacings } from '../Theme'
import { StyledIcon } from 'components/Icons/Icon'

export const StyledCheckboxWrapper = styled.div<{
  $disabled?: boolean
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: ${SiteSpacings.Spacing04.md}px;
  margin-bottom: ${SiteSpacings.Spacing03.md}px;

  ${StyledIcon} {
    stroke: ${Palette.Greyscale['white']};
  }

  .checkbox-label__wrapper {
    display: flex;
    align-items: center;
    gap: ${SiteSpacings.Spacing04.md}px;
  }

  .checkbox-icon__wrapper {
    height: ${Rem(40)};
    width: ${Rem(40)};
    background: ${Palette.Azure['500']};
    border-radius: ${Rem(100)};
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    ${({ $disabled }) =>
      $disabled &&
      css`
        background: ${Palette.Greyscale['700']};
        cursor: default;
        pointer-events: none;
      `};
  }
`

export const StyledCheckbox = styled.input<{ $error?: boolean }>`
  &[type='checkbox'] {
    appearance: none;
    border-radius: ${SiteSpacings.Spacing01.md}px;
    border: ${Rem(1)} solid ${Palette.Greyscale['800']};
    background: ${Palette.Greyscale['white']};
    margin: 0;
    height: ${Rem(24)};
    width: ${Rem(24)};
    position: relative;
    cursor: pointer;
    &:focus,
    &:focus-visible {
      outline: none;
    }
    &::before {
      content: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTYgMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yLjY2NjY5IDkuMzMzMzNMNi42NjY2OSAxMkwxMy4zMzM0IDQiIHN0cm9rZT0iI0ZGRiIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==');
      height: ${Rem(16)};
      width: ${Rem(16)};
      position: absolute;
      top: 50%;
      left: 50%;
      clip-path: polygon(25% 25%, 25% 25%, 25% 75%, 25% 75%);
      transition: clip-path 0.5s ease;
      opacity: 0;
    }
    &:checked::before {
      transform: translate(-50%, -50%);
      clip-path: polygon(25% 25%, 100% 20%, 100% 100%, 0% 100%);
      opacity: 1;
    }
    &:checked {
      border-color: ${Palette.Blue['800']};
      background-color: ${Palette.Blue['800']};
      transition: background-color 0.3s ease;
    }
    &:hover {
      border-color: ${({ disabled }) =>
        disabled ? Palette.Greyscale['700'] : Palette.Blue['600']};
    }
    ${({ disabled }) =>
      disabled &&
      css`
        background-color: ${Palette.Greyscale['100']};
        border-color: ${Palette.Greyscale['700']};
        cursor: default;
        pointer-events: none;
      `};
    ${({ $error }) =>
      $error &&
      css`
        border-color: ${Palette.Red['border_error']};
      `};
  }
`

export const StyledCheckboxLabel = styled.label<{ $disabled?: boolean }>`
  font-size: ${Rem(16)};
  color: ${Palette.Greyscale['black']};
  cursor: pointer;
  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${Palette.Greyscale['700']};
      cursor: default;
      pointer-events: none;
    `};
`

export const StyledErrorLabel = styled(StyledCheckboxLabel)<{
  $icon?: boolean
  $reverse?: boolean
}>`
  font-size: ${Rem(14)};
  margin-left: ${Rem(40)};
  color: ${Palette.Red['error']};
  cursor: default;
  font-style: italic;

  ${({ $icon, $reverse }) =>
    $icon && $reverse
      ? css`
          margin-left: ${Rem(56)};
        `
      : $reverse &&
        css`
          margin-left: 0;
        `};
`
