import CodiceFiscale from 'codice-fiscale-js'
import {
  isValidEmail,
  isValidName,
  noSpecialChars,
} from '../../../commons/utils'
import { ErediContextState, ErrorFields } from '../context/ErediContext'

export function postErediData(data: ErediContextState) {
  return fetch(`${process.env.A2A_DRUPAL_URL}a2a-api/eredi/add?_format=json`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((res) => res.json())
}

export function validateToken(token: string) {
  return fetch(
    `${process.env.A2A_DRUPAL_URL}a2a-api/eredi/get?_format=json&token=${token}`
  ).then((res) => res.json())
}

export function uploadErediFile(
  token: string,
  fileId: string,
  fileNames: string[]
) {
  return fetch(
    `${process.env.A2A_DRUPAL_URL}a2a-api/eredi/update?_format=json`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token: token,
        file: fileId,
        document_names: fileNames,
      }),
    }
  ).then((res) => res.json())
}

export function errorHandler(key: ErrorFields, value: string) {
  switch (key) {
    case 'obbligatorio':
      if (!value) return 'Campo obbligatorio'
      return ''
    case 'nome':
      if (!value) return 'Campo obbligatorio'
      if (value.length < 2 || !isValidName(value)) return 'Nome errato'
      return ''
    case 'cognome':
      if (!value) return 'Campo obbligatorio'
      if (value.length < 2 || !isValidName(value)) return 'Cognome errato'
      return ''
    case 'nazionalita':
      if (!value) return 'Campo obbligatorio'
      if (value.length < 2 || !isValidName(value)) return 'Nazionalità errata'
      return ''
    case 'email':
      if (!value) return 'Campo obbligatorio'
      if (!isValidEmail(value)) return 'Email errata'
      return ''
    case 'dataNascita': {
      if (!value) return 'Campo obbligatorio'
      if (!isValidDDMMYYY(value)) {
        return 'Data di nascita errata'
      }
      const timeDate = getTimeFromDDMMYYYY(value)
      if (timeDate && timeDate > Date.now()) {
        return 'Data di nascita errata'
      }
      return ''
    }
    case 'fornitura':
      if (!value) return 'Campo obbligatorio'
      // Only digits and spaces allowed
      if (!/^[\d\s]+$/.test(value.trim())) return 'Fornitura errata'
      return ''
    case 'telefono':
      if (!value) return 'Campo obbligatorio'
      // Check if value has letters
      if (/[a-zA-Z]/.test(value.trim())) return 'Telefono errato'
      return ''
    case 'ibanItaliano':
      if (!value) return 'Campo obbligatorio'
      if (value.length !== 27 || !noSpecialChars(value)) return 'IBAN errato'
      return ''
    default:
      return ''
  }
}

export function getGLTownSuggestion(value: string) {
  const myHeaders = new Headers()
  myHeaders.append('Referer', 'https://*.a2a.it')

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
  }

  return fetch(
    `https://cloud.streetmaster.it/suggest/SuggestServer?methodName=GetTownDirect&keyTR=${process.env.GEOLAB_KEY}&pTxt=${value}&type=1&typeRes=1&cnt=5`,
    requestOptions
  ).then((res) => res.json())
}

export function getGLStreetSuggestion(value: string, townKey: string) {
  const myHeaders = new Headers()
  myHeaders.append('Referer', 'https://*.a2a.it')

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
  }

  return fetch(
    `https://cloud.streetmaster.it/suggest/SuggestServer?methodName=GetStreetDirect&keyTR=${process.env.GEOLAB_KEY}&pTxt=${value}&tKey=${townKey}&type=1&typeRes=1&cnt=5`,
    requestOptions
  ).then((res) => res.json())
}

function createCodiceFiscaleNameSurname(name: string, surname: string) {
  if (!name || name.length < 1 || !surname || surname.length < 1) {
    throw new Error('Nome o cognome mancanti')
  }

  return CodiceFiscale.compute({
    name: name,
    surname: surname,
    gender: 'M',
    day: 14,
    year: 1974,
    month: 11,
    birthday: '1974-01-01',
    birthplace: 'MILANO',
    birthplaceProvincia: 'MI',
  })
}

export function isCodiceFiscaleValidByNameSurname(
  input: string,
  name: string,
  surname: string
) {
  if (!name || name.length < 1 || !surname || surname.length < 1) {
    return false
  }

  if (!input || input.length < 16 || !CodiceFiscale.check(input)) {
    return false
  }

  const createCF = createCodiceFiscaleNameSurname(name, surname)

  // Codice fiscale mismatch
  if (input.substring(0, 6) !== createCF.substring(0, 6)) {
    return false
  }

  return true
}

export const getTimeFromDDMMYYYY = (value: string) => {
  const [day, month, year] = value.split('/')
  const tryDate = new Date(`${month}/${day}/${year}`).getTime()

  if (isNaN(tryDate)) return undefined
  return tryDate
}

export function isValidDDMMYYY(value: string) {
  const [day, month, year] = value.split('/')
  const tryDate = new Date(`${month}/${day}/${year}`)

  if (isNaN(tryDate.getTime())) return false

  if (
    tryDate.getDate() === Number(day) &&
    tryDate.getMonth() + 1 === Number(month) &&
    tryDate.getFullYear() === Number(year)
  ) {
    return true
  }

  return false
}
