import { useContext } from 'react'
import { Input } from '../../../commons/FormComponents/Input'
import { StyledFormGrid } from '../FormDelegaRiscossione.style'
import { ErediContext } from '../context/ErediContext'
import {
  errorHandler,
  getTimeFromDDMMYYYY,
  isCodiceFiscaleValidByNameSurname,
  isValidDDMMYYY,
} from '../helpers/utils'
import { InputGeolabStreet, InputGeolabTown } from './InputGeolab'

export const FormDeceduto = () => {
  const { state, dispatch, errors, dispatchError, triggerValidation } =
    useContext(ErediContext)

  return (
    <StyledFormGrid>
      <Input
        id="deceduto-nome"
        label="Nome*"
        value={state.deceduto.nome}
        onChange={(value) =>
          dispatch({
            type: 'UPDATE_DECEDUTO_VALUE',
            payload: { nome: value },
          })
        }
        onFocus={() => {
          dispatchError({
            type: 'UPDATE_ERROR_VALUE',
            payload: {
              [`deceduto-nome`]: '',
            },
          })
        }}
        error={errors[`deceduto-nome`] || ''}
        isValid={errors[`deceduto-nome`] === ''}
        validate={() =>
          dispatchError({
            type: 'UPDATE_ERROR_VALUE',
            payload: {
              [`deceduto-nome`]: errorHandler('nome', state.deceduto.nome),
            },
          })
        }
        validateOnBlur
        triggerValidation={triggerValidation}
      />
      <Input
        id="deceduto-cognome"
        label="Cognome*"
        value={state.deceduto.cognome}
        onChange={(value) =>
          dispatch({
            type: 'UPDATE_DECEDUTO_VALUE',
            payload: { cognome: value },
          })
        }
        onFocus={() => {
          dispatchError({
            type: 'UPDATE_ERROR_VALUE',
            payload: {
              [`deceduto-cognome`]: '',
            },
          })
        }}
        error={errors[`deceduto-cognome`] || ''}
        isValid={errors[`deceduto-cognome`] === ''}
        validate={() =>
          dispatchError({
            type: 'UPDATE_ERROR_VALUE',
            payload: {
              [`deceduto-cognome`]: errorHandler(
                'cognome',
                state.deceduto.cognome
              ),
            },
          })
        }
        validateOnBlur
        triggerValidation={triggerValidation}
      />
      <InputGeolabTown
        id="deceduto-comune-nascita"
        label="Comune di nascita*"
        value={state.deceduto.comuneNascita}
        onChange={(value) =>
          dispatch({
            type: 'UPDATE_DECEDUTO_VALUE',
            payload: { comuneNascita: value },
          })
        }
        onFocus={() => {
          dispatchError({
            type: 'UPDATE_ERROR_VALUE',
            payload: {
              [`deceduto-comune-nascita`]: '',
            },
          })
        }}
        onSelect={(suggestion) => {
          dispatch({
            type: 'UPDATE_DECEDUTO_VALUE',
            payload: { comuneNascita: suggestion['Des'] },
          })
          dispatch({
            type: 'UPDATE_DECEDUTO_VALUE',
            payload: { provinciaNascita: suggestion['Prov'] },
          })
          dispatchError({
            type: 'UPDATE_ERROR_VALUE',
            payload: {
              [`deceduto-provincia-nascita`]: '',
            },
          })
        }}
        error={errors[`deceduto-comune-nascita`] || ''}
        isValid={errors[`deceduto-comune-nascita`] === ''}
        validate={() =>
          dispatchError({
            type: 'UPDATE_ERROR_VALUE',
            payload: {
              [`deceduto-comune-nascita`]: errorHandler(
                'obbligatorio',
                state.deceduto.comuneNascita
              ),
            },
          })
        }
        validateOnBlur
        triggerValidation={triggerValidation}
      />
      <Input
        id="deceduto-provincia-nascita"
        label="Provincia*"
        value={state.deceduto.provinciaNascita}
        onChange={(value) =>
          dispatch({
            type: 'UPDATE_DECEDUTO_VALUE',
            payload: { provinciaNascita: value },
          })
        }
        onFocus={() => {
          dispatchError({
            type: 'UPDATE_ERROR_VALUE',
            payload: {
              [`deceduto-provincia-nascita`]: '',
            },
          })
        }}
        error={errors[`deceduto-provincia-nascita`] || ''}
        isValid={errors[`deceduto-provincia-nascita`] === ''}
        validate={() =>
          dispatchError({
            type: 'UPDATE_ERROR_VALUE',
            payload: {
              [`deceduto-provincia-nascita`]: errorHandler(
                'obbligatorio',
                state.deceduto.provinciaNascita
              ),
            },
          })
        }
        validateOnBlur
        triggerValidation={triggerValidation}
      />
      <Input
        id="deceduto-data-nascita"
        label="Data di nascita*"
        placeholder="gg/mm/aaaa"
        value={state.deceduto.dataNascita}
        onChange={(value) =>
          dispatch({
            type: 'UPDATE_DECEDUTO_VALUE',
            payload: { dataNascita: value },
          })
        }
        onFocus={() => {
          dispatchError({
            type: 'UPDATE_ERROR_VALUE',
            payload: {
              [`deceduto-data-nascita`]: '',
            },
          })
        }}
        error={errors[`deceduto-data-nascita`] || ''}
        isValid={errors[`deceduto-data-nascita`] === ''}
        validate={() =>
          dispatchError({
            type: 'UPDATE_ERROR_VALUE',
            payload: {
              [`deceduto-data-nascita`]: errorHandler(
                'dataNascita',
                state.deceduto.dataNascita
              ),
            },
          })
        }
        validateOnBlur
        triggerValidation={triggerValidation}
      />
      <Input
        id="deceduto-nazionalita"
        label="Nazionalità*"
        value={state.deceduto.nazionalita}
        onChange={(value) =>
          dispatch({
            type: 'UPDATE_DECEDUTO_VALUE',
            payload: { nazionalita: value },
          })
        }
        onFocus={() => {
          dispatchError({
            type: 'UPDATE_ERROR_VALUE',
            payload: {
              [`deceduto-nazionalita`]: '',
            },
          })
        }}
        error={errors[`deceduto-nazionalita`] || ''}
        isValid={errors[`deceduto-nazionalita`] === ''}
        validate={() =>
          dispatchError({
            type: 'UPDATE_ERROR_VALUE',
            payload: {
              [`deceduto-nazionalita`]: errorHandler(
                'nazionalita',
                state.deceduto.nazionalita
              ),
            },
          })
        }
        validateOnBlur
        triggerValidation={triggerValidation}
      />
      <Input
        id="deceduto-codice-fiscale"
        label="Codice Fiscale*"
        value={state.deceduto.codiceFiscale}
        onChange={(value) =>
          dispatch({
            type: 'UPDATE_DECEDUTO_VALUE',
            payload: { codiceFiscale: value.toLocaleUpperCase() },
          })
        }
        onFocus={() => {
          dispatchError({
            type: 'UPDATE_ERROR_VALUE',
            payload: {
              [`deceduto-codice-fiscale`]: '',
            },
          })
        }}
        error={errors[`deceduto-codice-fiscale`] || ''}
        isValid={errors[`deceduto-codice-fiscale`] === ''}
        validate={() =>
          dispatchError({
            type: 'UPDATE_ERROR_VALUE',
            payload: {
              [`deceduto-codice-fiscale`]: !state.deceduto.codiceFiscale
                ? 'Campo obbligatorio'
                : !isCodiceFiscaleValidByNameSurname(
                      state.deceduto.codiceFiscale,
                      state.deceduto.nome,
                      state.deceduto.cognome
                    )
                  ? 'Codice fiscale errato'
                  : '',
            },
          })
        }
        validateOnBlur
        triggerValidation={triggerValidation}
      />
      <Input
        id="deceduto-data-decesso"
        label="Data di decesso*"
        placeholder="gg/mm/aaaa"
        value={state.deceduto.dataDecesso}
        onChange={(value) =>
          dispatch({
            type: 'UPDATE_DECEDUTO_VALUE',
            payload: { dataDecesso: value },
          })
        }
        onFocus={() => {
          dispatchError({
            type: 'UPDATE_ERROR_VALUE',
            payload: {
              [`deceduto-data-decesso`]: '',
            },
          })
        }}
        error={errors[`deceduto-data-decesso`] || ''}
        isValid={errors[`deceduto-data-decesso`] === ''}
        validate={() => {
          const timeNascita = getTimeFromDDMMYYYY(state.deceduto.dataNascita)
          const timeDecesso = getTimeFromDDMMYYYY(state.deceduto.dataDecesso)
          const isValidDecesso =
            timeDecesso &&
            timeNascita &&
            timeDecesso < Date.now() &&
            timeNascita < timeDecesso

          dispatchError({
            type: 'UPDATE_ERROR_VALUE',
            payload: {
              [`deceduto-data-decesso`]: !state.deceduto.dataDecesso
                ? 'Campo obbligatorio'
                : !isValidDecesso || !isValidDDMMYYY(state.deceduto.dataDecesso)
                  ? 'Data di decesso errata'
                  : '',
            },
          })
        }}
        validateOnBlur
        triggerValidation={triggerValidation}
      />
      <InputGeolabTown
        id="deceduto-comune-decesso"
        label="Comune di residenza alla data del decesso*"
        value={state.deceduto.comuneDecesso}
        onChange={(value) =>
          dispatch({
            type: 'UPDATE_DECEDUTO_VALUE',
            payload: { comuneDecesso: value },
          })
        }
        onSelect={(suggestion) => {
          dispatch({
            type: 'UPDATE_DECEDUTO_VALUE',
            payload: { comuneDecesso: suggestion.Des },
          })
          dispatch({
            type: 'UPDATE_DECEDUTO_VALUE',
            payload: { provinciaDecesso: suggestion.Prov },
          })
          dispatch({
            type: 'UPDATE_DECEDUTO_VALUE',
            payload: { codiceDecesso: suggestion.TownKey ?? suggestion.Key },
          })
          dispatchError({
            type: 'UPDATE_ERROR_VALUE',
            payload: {
              [`deceduto-provincia-decesso`]: '',
            },
          })
        }}
        onFocus={() => {
          dispatchError({
            type: 'UPDATE_ERROR_VALUE',
            payload: {
              [`deceduto-comune-decesso`]: '',
            },
          })
        }}
        error={errors[`deceduto-comune-decesso`] || ''}
        isValid={errors[`deceduto-comune-decesso`] === ''}
        validate={() =>
          dispatchError({
            type: 'UPDATE_ERROR_VALUE',
            payload: {
              [`deceduto-comune-decesso`]: errorHandler(
                'obbligatorio',
                state.deceduto.comuneDecesso
              ),
            },
          })
        }
        validateOnBlur
        triggerValidation={triggerValidation}
      />
      <Input
        id="deceduto-provincia-decesso"
        label="Provincia*"
        value={state.deceduto.provinciaDecesso}
        onChange={(value) =>
          dispatch({
            type: 'UPDATE_DECEDUTO_VALUE',
            payload: { provinciaDecesso: value },
          })
        }
        onFocus={() => {
          dispatchError({
            type: 'UPDATE_ERROR_VALUE',
            payload: {
              [`deceduto-provincia-decesso`]: '',
            },
          })
        }}
        error={errors[`deceduto-provincia-decesso`] || ''}
        isValid={errors[`deceduto-provincia-decesso`] === ''}
        validate={() =>
          dispatchError({
            type: 'UPDATE_ERROR_VALUE',
            payload: {
              [`deceduto-provincia-decesso`]: errorHandler(
                'obbligatorio',
                state.deceduto.provinciaDecesso
              ),
            },
          })
        }
        validateOnBlur
        triggerValidation={triggerValidation}
      />
      <InputGeolabStreet
        id="deceduto-indirizzo-decesso"
        label="Indirizzo di residenza alla data del decesso*"
        value={state.deceduto.indirizzoDecesso}
        townKey={state.deceduto.codiceDecesso ?? ''}
        onChange={(value) =>
          dispatch({
            type: 'UPDATE_DECEDUTO_VALUE',
            payload: { indirizzoDecesso: value },
          })
        }
        onSelect={(suggestion) => {
          dispatch({
            type: 'UPDATE_DECEDUTO_VALUE',
            payload: {
              indirizzoDecesso: `${suggestion.Dug} ${suggestion.Street}`,
            },
          })
        }}
        onFocus={() => {
          dispatchError({
            type: 'UPDATE_ERROR_VALUE',
            payload: {
              [`deceduto-indirizzo-decesso`]: '',
            },
          })
        }}
        error={errors[`deceduto-indirizzo-decesso`] || ''}
        isValid={errors[`deceduto-indirizzo-decesso`] === ''}
        validate={() =>
          dispatchError({
            type: 'UPDATE_ERROR_VALUE',
            payload: {
              [`deceduto-indirizzo-decesso`]: errorHandler(
                'obbligatorio',
                state.deceduto.indirizzoDecesso
              ),
            },
          })
        }
        validateOnBlur
        triggerValidation={triggerValidation}
      />
      <Input
        id="deceduto-numero-civico"
        label="Numero civico*"
        value={state.deceduto.numeroCivico}
        onChange={(value) =>
          dispatch({
            type: 'UPDATE_DECEDUTO_VALUE',
            payload: { numeroCivico: value },
          })
        }
        onFocus={() => {
          dispatchError({
            type: 'UPDATE_ERROR_VALUE',
            payload: {
              [`deceduto-numero-civico`]: '',
            },
          })
        }}
        error={errors[`deceduto-numero-civico`] || ''}
        isValid={errors[`deceduto-numero-civico`] === ''}
        validate={() =>
          dispatchError({
            type: 'UPDATE_ERROR_VALUE',
            payload: {
              [`deceduto-numero-civico`]: errorHandler(
                'obbligatorio',
                state.deceduto.numeroCivico
              ),
            },
          })
        }
        validateOnBlur
        triggerValidation={triggerValidation}
      />
    </StyledFormGrid>
  )
}

export const FormDecedutoReadOnly = () => {
  const { state } = useContext(ErediContext)

  return (
    <StyledFormGrid>
      <Input
        label="Nome*"
        value={state.deceduto.nome}
        onChange={() => {}}
        readOnly
      />
      <Input
        label="Cognome*"
        value={state.deceduto.cognome}
        onChange={() => {}}
        readOnly
      />
      <Input
        label="Comune di nascita*"
        value={state.deceduto.comuneNascita}
        onChange={() => {}}
        readOnly
      />
      <Input
        label="Provincia*"
        value={state.deceduto.provinciaNascita}
        onChange={() => {}}
        readOnly
      />
      <Input
        label="Data di nascita*"
        placeholder="gg/mm/aaaa"
        value={state.deceduto.dataNascita}
        onChange={() => {}}
        readOnly
      />
      <Input
        label="Nazionalità*"
        value={state.deceduto.nazionalita}
        onChange={() => {}}
        readOnly
      />
      <Input
        label="Codice Fiscale*"
        value={state.deceduto.codiceFiscale}
        onChange={() => {}}
        readOnly
      />
      <Input
        label="Data di decesso*"
        placeholder="gg/mm/aaaa"
        value={state.deceduto.dataDecesso}
        onChange={() => {}}
        readOnly
      />
      <Input
        label="Comune di residenza alla data del decesso*"
        value={state.deceduto.comuneDecesso}
        onChange={() => {}}
        readOnly
      />
      <Input
        label="Provincia*"
        value={state.deceduto.provinciaDecesso}
        onChange={() => {}}
        readOnly
      />
      <Input
        label="Indirizzo di residenza alla data del decesso*"
        value={state.deceduto.indirizzoDecesso}
        onChange={() => {}}
        readOnly
      />
      <Input
        label="Numero civico*"
        value={state.deceduto.numeroCivico}
        onChange={() => {}}
        readOnly
      />
    </StyledFormGrid>
  )
}
